import React, { useEffect, useState } from "react";
import {
	CircularProgress,
	Backdrop,
	TextField,
	Button,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useAPI } from "../../../apiContext";
import Swal from "sweetalert2";

const Terms = () => {
	const [submitting, setSubmitting] = useState(false);
	const { token } = useAPI();

	const [termsAndCond, setTermsAndCond] = useState();
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_PATH}/terms`)
			.then((res) => {
				setTermsAndCond(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const [addTerms, setAddTerms] = useState();

	return (
		<Box sx={{ p: 2 }}>
			<Typography variant='h4' sx={{ fontWeight: "bold" }}>
				Terms & Conditions
			</Typography>

			<Typography sx={{ py: 2 }}>
				Last Updated: {termsAndCond?.submitTime}
			</Typography>
			<TextField
				required
				fullWidth
				multiline
				rows={15}
				defaultValue={termsAndCond?.terms}
				label='Share Text'
				onChange={(e) => setAddTerms(e.target.value)}
				InputLabelProps={{
					shrink: true,
				}}
			/>

			<Button
				onClick={async () => {
					setSubmitting(true);
					try {
						await axios
							.put(
								`${process.env.REACT_APP_API_PATH}/manageTerms`,
								{
									terms: addTerms,
									type: "Terms",
									submitTime: new Date().toLocaleString(),
								},
								{
									headers: {
										Authorization: `Bearer ${token}`,
									},
								},
							)
							.then(function (response) {
								setSubmitting(false);
								Swal.fire({
									icon: "success",
									title: "Saved Successfully",
									showConfirmButton: false,
									timer: 1500,
								});
							});
					} catch (error) {
						if (error.response.status) {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: error.response.data,
							});
						} else {
							console.log(error);
						}
					}
				}}
				variant='contained'
				sx={{
					fontWeight: "bold",
					border: "2px solid",
					backgroundColor: "transparent",
					borderRadius: "25px",
					width: "100%",
					my: 1,
				}}>
				Save
			</Button>

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default Terms;
