import React, { useState } from "react";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import axios from "axios";
import Swal from "sweetalert2";
import { useAPI } from "../../../apiContext";

const BannerPhoto = () => {
	const { loading, setLoading, databyEmail, userMediaMemory, token } = useAPI();

	const [open, setOpen] = useState(false);
	const [image, setImage] = useState(null);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleImageChange = (event) => {
		const file = event.target.files[0];
		if (file && file.size > 1.5 * 1024 * 1024) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "File size is too big! Max file size is 1.5MB",
			});
		} else {
			setImage(file);
		}
	};

	const handleSave = async () => {
		const data = new FormData();
		data.append("file", image);
		data.append("upload_preset", "DigiCardsPro-Banner");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/freedomvirtualsolutions/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		const profilephoto = {
			type: "banner",
			imageLink2: file?.secure_url || databyEmail?.banner?.imageLink2,
			public_id: file?.public_id,
			pre_public_id: databyEmail?.banner?.pre_public_id,
			bytes: file?.bytes,
			submitTime: new Date().toLocaleString(),
		};
		if (await file?.secure_url) {
			try {
				await axios
					.put(
						`${process.env.REACT_APP_API_PATH}/banner/${databyEmail?._id}`,
						profilephoto,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					)
					.then(function (response) {
						Swal.fire({
							icon: "success",
							title: "Your photo updated Successfully",
							showConfirmButton: false,
							timer: 1500,
						});
						setLoading(false);
						setOpen(false);
					});
			} catch (error) {
				if (error.response.status) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: error.response.data,
					});
				} else {
					console.log(error);
				}
			}
		}
	};

	const handleCancel = () => {
		setImage(null);
		setOpen(false);
	};

	return (
		<>
			<Box sx={{ position: "relative" }}>
				<Button
					variant='outlined'
					onClick={handleOpen}
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						backgroundColor: "gray !important",
						border: "1px solid",
					}}>
					Chnage Image
				</Button>
				{!image ? (
					<img
						src={databyEmail?.banner?.imageLink2 || "/banner.png"}
						alt='selected'
						style={{ width: "100%" }}
					/>
				) : (
					<img
						src={URL.createObjectURL(image)}
						alt='selected'
						style={{ width: "100%" }}
					/>
				)}
			</Box>

			<Dialog open={open} onClose={handleClose}>
				{parseFloat(userMediaMemory?.mediaUsed) <
				parseFloat(userMediaMemory?.mediaLimit) ? (
					<>
						{!loading ? (
							<>
								<DialogTitle>Select Image</DialogTitle>
								<DialogContent>
									{image ? (
										<img
											src={URL.createObjectURL(image)}
											alt='selected'
											style={{ width: "100%" }}
										/>
									) : (
										<Typography>No image selected</Typography>
									)}
									<input
										type='file'
										accept='image/*'
										onChange={handleImageChange}
										sx={{ mt: 2 }}
									/>
								</DialogContent>
								<DialogActions
									sx={{ display: "flex", justifyContent: "space-between" }}>
									<Button onClick={handleCancel}>Cancel</Button>
									{image && (
										<div>
											<IconButton onClick={() => setImage(null)} sx={{ mr: 1 }}>
												<CancelIcon />
											</IconButton>
											<IconButton onClick={handleSave}>
												<SaveAltIcon />
											</IconButton>
										</div>
									)}
								</DialogActions>
							</>
						) : (
							<Backdrop
								open={open}
								sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
								<CircularProgress
									color='primary'
									size={60}
									sx={{
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}
								/>
							</Backdrop>
						)}
					</>
				) : (
					<Typography sx={{ p: 2 }}>
						<span style={{ color: "red" }}>
							You have reached your media limit. Please upgrade your plan to
							upload more images.
						</span>
						<br /> Memory Used : {parseFloat(userMediaMemory?.mediaUsed)} MB
						<br />
						Memory Plan : {parseFloat(userMediaMemory?.mediaLimit)} MB
					</Typography>
				)}
			</Dialog>
		</>
	);
};

export default BannerPhoto;
