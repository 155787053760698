import React, { useState, useRef, useEffect } from 'react';
import { sendMessageToOpenAI, getSuggestedQuestions } from '../../Services/openaiService';
import './Ai.css';
import Swal from 'sweetalert2';
import { Paper, IconButton, Typography, Chip, Tooltip, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import GetAuth from '../../GetAuth';

const Ai = () => {
    const [messages, setMessages] = useState([]);
    const [conversationHistory, setConversationHistory] = useState([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [typingText, setTypingText] = useState('');
    const [suggestions] = useState(getSuggestedQuestions());
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);
    const { user } = GetAuth();
    
    // Load conversation from localStorage if available
    useEffect(() => {
        if (user?.email) {
            try {
                const savedMessages = localStorage.getItem(`digicardspro_chat_${user.email}`);
                if (savedMessages) {
                    const parsedMessages = JSON.parse(savedMessages);
                    setMessages(parsedMessages.messages || []);
                    setConversationHistory(parsedMessages.history || []);
                } else {
                    // Initialize with welcome message for new users
                    setMessages([
                        { 
                            text: "Hello! I'm your DigiCardsPro AI assistant. How can I help you optimize your professional networking experience today?", 
                            sender: 'ai' 
                        }
                    ]);
                }
            } catch (error) {
                console.error("Error loading conversation history:", error);
                // Initialize with welcome message on error
                setMessages([
                    { 
                        text: "Hello! I'm your DigiCardsPro AI assistant. How can I help you optimize your professional networking experience today?", 
                        sender: 'ai' 
                    }
                ]);
            }
        } else {
            // Initialize with welcome message for non-logged in users
            setMessages([
                { 
                    text: "Hello! I'm your DigiCardsPro AI assistant. How can I help you optimize your professional networking experience today?", 
                    sender: 'ai' 
                }
            ]);
        }
    }, [user?.email]);

    // Save conversation to localStorage whenever it changes
    useEffect(() => {
        if (user?.email && messages.length > 0) {
            localStorage.setItem(
                `digicardspro_chat_${user.email}`, 
                JSON.stringify({
                    messages,
                    history: conversationHistory
                })
            );
        }
    }, [messages, conversationHistory, user?.email]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages, typingText]);

    const sendMessage = async (e, suggestedQuestion = null) => {
        if (e) e.preventDefault();
        
        const messageToSend = suggestedQuestion || input;
        if (!messageToSend.trim()) return;
        
        // Add user message to chat
        const userMessage = { text: messageToSend, sender: 'user' };
        setMessages(prev => [...prev, userMessage]);
        
        if (!suggestedQuestion) setInput('');
        setIsLoading(true);
        
        try {
            // Prepare conversation history for context
            const history = conversationHistory.slice(-5); // Keep last 5 exchanges for context
            
            // Send message to OpenAI
            const responseText = await sendMessageToOpenAI(
                messageToSend, 
                history,
                { 
                    maxTokens: 500,
                    presencePenalty: 0.2,
                    // Personalize for logged-in users
                    systemPrompt: user?.email 
                        ? `You are DigiCardsPro Assistant, an AI expert in digital business cards and professional networking. You are talking to ${user.email}. Be professional, friendly, and focus on delivering actionable advice.`
                        : undefined
                }
            );
            
            // Simulate typing effect for more natural feel
            simulateTyping(responseText);
            
            // Update conversation history
            setConversationHistory(prev => [
                ...prev, 
                { user: messageToSend, assistant: responseText }
            ]);
            
        } catch (error) {
            console.error('Error sending message:', error);
            
            Swal.fire({
                icon: "error",
                title: "AI Service Error",
                text: error.message || "Could not connect to AI service. Please try again later.",
                showConfirmButton: true,
            });
            
            const errorMessage = { 
                text: 'Sorry, I encountered an error processing your request. Please try again later.', 
                sender: 'ai' 
            };
            setMessages(prev => [...prev, errorMessage]);
            setIsLoading(false);
        }
    };

    const simulateTyping = (text) => {
        let i = 0;
        setTypingText('');
        
        const typingInterval = setInterval(() => {
            if (i < text.length) {
                setTypingText(prev => prev + text.charAt(i));
                i++;
            } else {
                clearInterval(typingInterval);
                setMessages(prev => [...prev, { text, sender: 'ai' }]);
                setTypingText('');
                setIsLoading(false);
            }
        }, 10); // Speed of typing simulation
    };

    const clearConversation = () => {
        Swal.fire({
            title: 'Clear conversation?',
            text: "This will remove all messages in this conversation.",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, clear it'
        }).then((result) => {
            if (result.isConfirmed) {
                setMessages([{ 
                    text: "Hello! I'm your DigiCardsPro AI assistant. How can I help you optimize your professional networking experience today?", 
                    sender: 'ai' 
                }]);
                setConversationHistory([]);
                
                if (user?.email) {
                    localStorage.removeItem(`digicardspro_chat_${user.email}`);
                }
            }
        });
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            icon: 'success',
            title: 'Copied to clipboard',
            showConfirmButton: false,
            timer: 1500
        });
    };

    return (
        <Paper elevation={3} className="ai-chat-container">
            <div className="chat-header">
                <Typography variant="h5" component="h2">DigiCardsPro AI Assistant</Typography>
                <div className="header-actions">
                    <Tooltip title="Clear conversation">
                        <IconButton 
                            onClick={clearConversation} 
                            size="small" 
                            color="inherit" 
                            aria-label="clear conversation"
                        >
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Refresh">
                        <IconButton 
                            onClick={() => window.location.reload()} 
                            size="small" 
                            color="inherit" 
                            aria-label="refresh"
                        >
                            <AutorenewIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            
            <div className="chat-messages">
                {messages.map((message, index) => (
                    <div 
                        key={index} 
                        className={`message ${message.sender === 'user' ? 'user-message' : 'ai-message'}`}
                    >
                        <div className="message-content">
                            {message.text}
                        </div>
                        {message.sender === 'ai' && (
                            <div className="message-actions">
                                <Tooltip title="Copy to clipboard">
                                    <IconButton
                                        onClick={() => copyToClipboard(message.text)}
                                        size="small"
                                        className="copy-button"
                                    >
                                        <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                ))}
                
                {isLoading && typingText && (
                    <div className="message ai-message typing">
                        <div className="message-content">
                            {typingText}
                        </div>
                    </div>
                )}
                
                {isLoading && !typingText && (
                    <div className="message ai-message loading">
                        <CircularProgress size={20} thickness={6} />
                    </div>
                )}
                
                <div ref={messagesEndRef} />
            </div>
            
            {messages.length === 1 && (
                <div className="suggestions">
                    <div className="suggestion-header">
                        <LightbulbIcon fontSize="small" />
                        <Typography variant="subtitle2">Suggested questions</Typography>
                    </div>
                    <div className="suggestion-chips">
                        {suggestions.map((question, index) => (
                            <Chip
                                key={index}
                                label={question}
                                onClick={() => sendMessage(null, question)}
                                className="suggestion-chip"
                                clickable
                                disabled={isLoading}
                            />
                        ))}
                    </div>
                </div>
            )}
            
            <form className="chat-input-form" onSubmit={sendMessage}>
                <input
                    ref={inputRef}
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Ask DigiCardsPro Assistant anything..."
                    disabled={isLoading}
                    onFocus={() => inputRef.current.classList.add('focused')}
                    onBlur={() => inputRef.current.classList.remove('focused')}
                />
                <IconButton 
                    type="submit" 
                    className="send-button" 
                    disabled={isLoading || !input.trim()}
                    color="primary"
                    aria-label="send message"
                >
                    {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
                </IconButton>
            </form>
        </Paper>
    );
};

export default Ai;