import {
	Backdrop,
	Card,
	CardMedia,
	Container,
	Fade,
	Modal,
	Rating,
	Typography,
} from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	maxWidth: "95vw",
	maxHeight: "90vh",
	overflow: "visible",
	borderRadius: 3,
};

const Review = ({ open, setOpen, view }) => {
	return (
		<Container>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={open}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={open}>
					<Box sx={style}>
						<Box sx={{ mx: "auto" }}>
							<Card
								className='borderColor'
								sx={{
									pb: 2,
									my: 1,
									minWidth: "300px",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									alignContent: "center",
									overflow: "visible",
									border: "2px solid ",
									borderRadius: 3,
								}}>
								<CancelIcon
									onClick={() => setOpen(false)}
									className='textColor'
									sx={{
										position: "fixed",
										top: "13px",
										right: "5px",
										cursor: "pointer",
									}}
								/>
								<CardMedia
									component='img'
									className='borderColor'
									style={{
										marginTop: -70,
										borderRadius: "50%",
										width: "150px",
										height: "150px",
										border: "5px solid ",
										backgroundColor: "white",
									}}
									image={view?.userPhoto || "/user.jpg"}
									alt=''
								/>
								<Rating
									sx={{ mt: 1.5 }}
									name='read-only'
									value={view?.rating + 0}
									readOnly
								/>
								<Typography
									gutterBottom
									variant='h6'
									component='div'
									className='textColor'
									sx={{ mt: 1, fontWeight: "bold" }}>
									{view?.userName}
								</Typography>

								<Typography variant='body2' sx={{ mt: 1, px: 1 }}>
									{view?.userReview}
								</Typography>
							</Card>
						</Box>
					</Box>
				</Fade>
			</Modal>
		</Container>
	);
};

export default Review;
