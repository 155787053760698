import { Backdrop, Box, Button, Chip, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Support from "./Support";
import GetAuth from "../../../GetAuth";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const AllSupports = () => {
	const [open, setOpen] = React.useState(false);
	const [support, setSupport] = useState();
	const handleOpen = (view) => {
		setSupport(view);
		setOpen(true);
	};
	const [loading, setLoading] = useState(false);
	const [supports, setSupports] = useState();
	const { token } = GetAuth();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const supports = await axios.get(
					`${process.env.REACT_APP_API_PATH}/supports`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);
				setSupports(supports?.data?.reverse());
			} catch (error) {
				if (error.response.status) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: error.response.data,
					});
				} else {
					console.log(error);
				}
			}
		};

		if (token) {
			fetchData();
		}
	}, [loading, token, open]);
	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setLoading(true);
				try {
					await axios
						.delete(`${process.env.REACT_APP_API_PATH}/support/${id}`, {
							headers: {
								Authorization: `Bearer ${token}`,
							},
						})
						.then(function (response) {
							Swal.fire(
								"Deleted!",
								"That support has been deleted.",
								"success",
							);
							setLoading(false);
						});
				} catch (error) {
					if (error.response.status) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: error.response.data,
						});
					} else {
						console.log(error);
					}
				}
			}
		});
	};
	const columns = [
		{
			field: "userId",
			headerName: "User Id",
			minWidth: 200,
			flex: 1,
		},
		{
			field: "solved",
			headerName: "Solved ?",
			minWidth: 200,
			flex: 1,
			filterable: false,
			renderCell: (params) => {
				return (
					<>
						{params.row?.solved === true ? (
							<Chip label='Solved' color='success' />
						) : (
							<Chip label='On Progress' color='error' />
						)}
					</>
				);
			},
		},
		{
			field: "submitTime",
			headerName: "Submit Time",
			minWidth: 220,
			flex: 1,
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 310,
			filterable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<Button
							onClick={() => handleOpen(params.row)}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<RemoveRedEyeIcon />
						</Button>
						<Button
							className='button border'
							onClick={() => handleDelete(params.row?._id)}
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<DeleteIcon />
						</Button>
					</>
				);
			},
		},
	];
	return (
		<Box sx={{ p: 2 }}>
			{supports && (
				<Box
					sx={{
						width: "100%",
						height: { md: "80vh", xs: "90vh" },
						"& .MuiDataGrid-toolbarContainer .MuiButtonBase-root": {
							border: "1px solid",
							fontSize: "0.75rem",
							padding: "0px 5px",
							textTransform: "capitalize",
						},
						"& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root": {
							fontSize: "1rem",
						},
					}}>
					<DataGrid
						componentsProps={{
							panel: {
								sx: {
									"& .MuiDataGrid-panelContent": {
										maxWidth: "100vw !important",
									},
								},
							},
						}}
						rows={supports || []}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!supports || loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{open && (
				<Support
					open={open}
					setOpen={setOpen}
					support={support}
					setDeleted={setLoading}
					token={token}
				/>
			)}
		</Box>
	);
};

export default AllSupports;
