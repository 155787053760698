import React, { useEffect } from "react";
import {
	Container,
	Grid,
	Backdrop,
	CircularProgress,
	LinearProgress,
	CardMedia,
	Tooltip,
	Fade,
	Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import {
	useCreateUserWithEmailAndPassword,
	useSendEmailVerification,
} from "react-firebase-hooks/auth";
import auth from "../Firebase/firebase.config";
import SelectPackage from "./SelectPackage";
import { useParams } from "react-router-dom";

const CreateAcc = () => {
	const { id } = useParams();
	const [url, setUrl] = React.useState("");
	const [createUserWithEmailAndPassword, user, loading, error] =
		useCreateUserWithEmailAndPassword(auth);
	const [sendEmailVerification, sending] = useSendEmailVerification(auth);
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [datas, setDatas] = useState();

	const [userRegistered, setUserRegistered] = useState(false);
	const [postSent, setPostSent] = useState(false);
	const [checking, setChecking] = useState(false);
	const [checkAvl, setCheckAvl] = useState(false);

	React.useEffect(() => {
		if (error) {
			Swal.fire({
				icon: "error",
				title: `${error?.message}`,
				showConfirmButton: true,
			});
			setSubmitting(false);
		}
		if (user) {
			setUserRegistered(true);
		}
	}, [error, user]);
	const [packageSelected, setPackageSelected] = useState();

	React.useEffect(() => {
		if (userRegistered && !postSent) {
			sendEmailVerification();
			axios
				.post(`${process.env.REACT_APP_API_PATH}/makeNewUser`, {
					url: datas?.url,
					email: datas?.email,
					name: datas?.name,
					title: datas?.title,
					phone: datas?.phone,
					referredBy: id || "No Referrals",
					time: datas?.time,
					company: datas?.company,
					...packageSelected,
				})
				.then(function (response) {
					setSubmitting(false);
					Swal.fire({
						icon: "success",
						title: "Your Successfully Requested To Make New Card",
						showConfirmButton: true,
					});
					window.location.replace("/dashboard");
				})
				.catch(function (error) {
					console.log(error);
				});
			setPostSent(true);
		}
	}, [
		datas,
		id,
		packageSelected,
		postSent,
		sendEmailVerification,
		userRegistered,
	]);

	const handleInputChange = (e) => {
		const dm = e.target.value;
		const str = dm.toString();
		setUrl(str?.toLowerCase().replace(/[^a-zA-Z0-9]/g, ""));
		checkAvl?.url === url ? setCheckAvl(true) : setCheckAvl(false);
	};

	const [submitting, setSubmitting] = useState(false);

	const { handleSubmit, register } = useForm();
	const onSubmit = ({ name, phone, title, company }) => {
		setDatas({
			url,
			email: email,
			name: name,
			title,
			phone: phone,
			referredBy: id,
			company: company,
			time: new Date().toLocaleString(),
			...packageSelected,
		});
		setSubmitting(true);
		createUserWithEmailAndPassword(email, password);
	};

	const [agree, setAgree] = useState(false);
	const [terms, setTerms] = useState(false);
	const [termsAndCond, setTermsAndCond] = useState();
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_PATH}/terms`)
			.then((res) => {
				setTermsAndCond(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	return (
		<Box sx={{ p: 2 }}>
			{packageSelected ? (
				<Container
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						minHeight: "100vh",
						width: "100vw",
					}}>
					<Grid
						container
						spacing={2}
						className='borderColor'
						sx={{
							alignItems: "stretch",
							justifyContent: "center",
							border: "1px solid",
							borderRadius: "10px",
						}}>
						<Grid item xs={12} md={6} sx={{ borderRadius: "10px 0 0 10px" }}>
							<Box
								className='textColor'
								sx={{
									p: 3,
								}}>
								<CardMedia
									image='/digicard.jpg'
									alt='loading logo'
									sx={{
										width: 170,
										height: 110,
										mx: "auto",
										my: 2,
									}}
								/>
								<Typography variant='h5' mb={2}>
									Connect, Impress, and Grow with DigiCardsPro
								</Typography>
								<Typography mb={2}>
									Effortlessly showcase your professional identity and make
									meaningful connections with our Digital Business Card. With
									our innovative platform, you can create a sleek and dynamic
									digital representation of your business profile, complete with
									contact details, social media links, and a personalized touch.
									Stand out from the crowd, leave a lasting impression, and
									easily share your information with clients, colleagues, and
									prospects. Say goodbye to paper business cards and embrace the
									convenience and versatility of our Digital Business Card
									solution, empowering you to network smarter and make your mark
									in the digital age.
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} md={6} sx={{ m: "auto" }}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Box sx={{ p: 3 }}>
									<Typography
										variant='h5'
										mb={2}
										className='textColor'
										fontWeight='bold'>
										Sign Up
									</Typography>
									<Grid container spacing={1.3}>
										<Grid item xs={12}>
											<TextField
												autoComplete='off'
												size='small'
												required
												fullWidth
												id='name'
												label='Your Name'
												name='name'
												autoFocus
												sx={{ maxWidth: "100%" }}
												{...register("name", { required: true })}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												size='small'
												required
												fullWidth
												id='company'
												label='Your Company Name'
												name='company'
												autoComplete='off'
												autoFocus
												sx={{ maxWidth: "100%" }}
												{...register("company", { required: true })}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												size='small'
												required
												fullWidth
												id='title'
												label='Your Work Title'
												name='title'
												autoComplete='off'
												autoFocus
												sx={{ maxWidth: "100%" }}
												{...register("title", { required: true })}
											/>
										</Grid>

										<Grid item xs={12}>
											<TextField
												size='small'
												required
												fullWidth
												id='email'
												label='Email Address'
												name='email'
												autoComplete='off'
												autoFocus
												onChange={(e) => setEmail(e.target.value)}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												size='small'
												required
												fullWidth
												name='password'
												label='Password'
												type='password'
												id='password'
												autoComplete='new-password'
												onChange={(e) => setPassword(e.target.value)}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												size='small'
												required
												fullWidth
												id='phone'
												label='Your Phone Number'
												name='phone'
												autoComplete='off'
												autoFocus
												sx={{ maxWidth: "100%" }}
												{...register("phone", { required: true })}
											/>
										</Grid>
										<Grid item xs={12}>
											{checking ? (
												<LinearProgress />
											) : (
												<TextField
													sx={{
														"& label.Mui-focused, .MuiFormHelperText-root": {
															color:
																checkAvl?.find === true && "red !important",
														},
														"& .MuiInput-underline:after": {
															borderBottomColor:
																checkAvl?.find === true && "red !important",
														},

														"& .MuiOutlinedInput-root": {
															"& fieldset": {
																borderColor:
																	checkAvl?.find === true && "red !important",
															},
														},
													}}
													size='small'
													required
													fullWidth
													label='Select a username'
													name='url'
													autoFocus
													autoComplete='off'
													helperText={checkAvl?.message}
													value={url}
													onChange={(e) => handleInputChange(e)}
												/>
											)}
										</Grid>
										<Grid item xs={12} className='textColor'>
											<input
												type='checkbox'
												checked={agree}
												onChange={() => setAgree(!agree)}
											/>{" "}
											<span
												style={{
													textDecoration: "underline",
													cursor: "pointer",
												}}
												onClick={() => setTerms(true)}>
												I agree to the Terms and Conditions.
											</span>
										</Grid>
										<Grid item xs={12}>
											<Typography
												variant='body2'
												className='textColor'
												sx={{ textDecoration: "underline", cursor: "pointer" }}
												onClick={() => window.location.replace("/login")}>
												Already have an account? Log In !
											</Typography>{" "}
										</Grid>
										<Grid item xs={12}>
											{checkAvl?.find === false ? (
												<Tooltip
													open={!agree}
													title={"Please check the terms and conditions."}
													arrow>
													<Button
														className='bgColor'
														type='submit'
														variant='contained'
														sx={{
															px: 2,
															backgroundColor: agree
																? "green !important"
																: "#ccc !important",
															pointerEvents: agree ? "auto" : "none",
														}}>
														Sign Up Now !
													</Button>
												</Tooltip>
											) : (
												<>
													<Button
														className='bgColor'
														sx={{
															px: 2,
															pointerEvents: !url && "none",
															backgroundColor: !url && "#ccc !important",
														}}
														onClick={() => {
															setChecking(true);
															axios
																.get(
																	`${process.env.REACT_APP_API_PATH}/checkUserName/${url}`,
																)
																.then((res) => {
																	setCheckAvl(res?.data);
																	setChecking(false);
																})
																.catch((err) => {
																	console.log(err);
																});
														}}>
														{url ? "Check Availability" : "Sign Up Now !"}
													</Button>
												</>
											)}
										</Grid>
									</Grid>
								</Box>
							</form>
						</Grid>
					</Grid>
				</Container>
			) : (
				<SelectPackage setPackageSelected={setPackageSelected} />
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading || submitting || sending}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={terms}
				onClose={() => setTerms(false)}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}>
				<Fade in={terms}>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: "100%",
							height: "95vh",
							bgcolor: "white",
							boxShadow: 24,
							px: 2,
							py: 4,
							maxWidth: { md: "50vw", xs: "90vw" },
							textAlign: "left !important",
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
						}}>
						<Typography
							variant='h5'
							gutterBottom
							className='textColor'
							sx={{
								fontWeight: "bold",
								textAlign: "center",
							}}>
							Terms and Conditions
						</Typography>
						<Box
							className='borderColor'
							sx={{
								borderTop: "2px solid",
								py: 2,
								alignItems: "center",
								overflowY: "scroll",
								height: "81vh",
							}}>
							<Typography
								variant='body1'
								gutterBottom
								className='textColor'
								sx={{ whiteSpace: "pre-line" }}>
								{termsAndCond?.terms}
							</Typography>
						</Box>
						<Button
							className='bgColor'
							sx={{ mt: 3, width: "100px", mx: "auto" }}
							variant='contained'
							onClick={() => setTerms(false)}>
							Close
						</Button>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
};

export default CreateAcc;
