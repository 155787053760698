import { Button, Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import About from "../../Homepage/Components/About/About";
import Banner from "../../Homepage/Components/Banner/Banner";
import Buttons from "../../Homepage/Components/Buttons/Buttons";
import Contact from "../../Homepage/Components/Contact/Contact";
import CopyRight from "../../Homepage/Components/CopyRight/CopyRight";
import Gallery from "../../Homepage/Components/Gallery/Gallery";
import Map from "../../Homepage/Components/Map/Map";
import Reviews from "../../Homepage/Components/Reviews/Reviews";
import ShareButton from "../../Homepage/Components/ShareButton/ShareButton";
import Video from "../../Homepage/Components/Video/Video";
import AddVideo from "../AddVideo/AddVideo";
import AllMails from "../AllMails/AllMails";
import AllReviews from "../AllReviews/AllReviews";
import BannerPhoto from "../BannerPhoto/BannerPhoto";
import ButtonsLink from "../ButtonsLink/ButtonsLink";
import Details from "../Details/Details";
import Galleryphoto from "../Galleryphoto/Galleryphoto";
import Headline from "../Headline/Headline";
import ManageMap from "../ManageMap/ManageMap";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axios from "axios";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import HtmlTooltip from "../HtmlTooltip";
import { reactLocalStorage } from "reactjs-localstorage";

const InActiveCard = ({
	edit,
	setEdit,
	databyEmail,
	setLoading,
	token,
	toltips,
	setToltips,
}) => {
	const findFeature = (feature) => {
		return databyEmail?.features?.find((item) => item?.name === feature);
	};

	const setFeatures = async ({ name, status }) => {
		Swal.fire({
			title: "Are you sure?",
			text: `You want to ${status ? "Active" : "Deactive"} this?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: status ? "Yes, Active it!" : "Yes, Deactive it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setLoading(true);
				try {
					await axios
						.put(
							`${process.env.REACT_APP_API_PATH}/FeatureOnOff/${databyEmail?._id}`,
							{ feature: name, status },
							{
								headers: {
									Authorization: `Bearer ${token}`,
								},
							},
						)
						.then(function (response) {
							setLoading(false);
							Swal.fire({
								icon: "success",
								title: "Feature Updated Successfully",
								showConfirmButton: false,
								timer: 1500,
							});
						});
				} catch (error) {
					if (error.response.status) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: error.response.data,
						});
					} else {
						console.log(error);
					}
				}
			}
		});
	};

	const featureActiveBtn = (feature) => {
		return (
			<Box
				className='borderColor'
				sx={{
					position: "absolute",
					top: -11,
					right: -11,
					zIndex: 999,
					backgroundColor: "white",
					lineHeight: 0,
					border: "2px solid",
					borderRadius: "5px",
					cursor: "pointer",
				}}>
				{findFeature(feature)?.status === true ? (
					<Tooltip title='Hide This From Your Card'>
						<HtmlTooltip
							open={toltips?.hideComponent}
							title={
								<Box>
									<Typography className='textColor'>
										Use this button if you want to hide this section from your
										card.
									</Typography>
									<Button
										sx={{ border: "1px solid", py: 0, mt: 1.5 }}
										onClick={() => {
											setToltips({
												...toltips,
												hideComponent: false,
												showComponent: true,
											});
											reactLocalStorage.setObject("toltips", {
												...toltips,
												hideComponent: false,
												showComponent: true,
											});
										}}>
										Ok
									</Button>
								</Box>
							}>
							<CancelIcon
								fontSize='large'
								sx={{ color: "red" }}
								onClick={() => setFeatures({ name: feature, status: false })}
							/>
						</HtmlTooltip>
					</Tooltip>
				) : (
					<Tooltip title='Show This In Your Card'>
						<HtmlTooltip
							open={toltips?.showComponent}
							title={
								<Box>
									<Typography className='textColor'>
										Use this button if you want to show this section in your
										card.
									</Typography>
									<Button
										sx={{ border: "1px solid", py: 0, mt: 1.5 }}
										onClick={() => {
											setToltips({
												...toltips,
												showComponent: false,
											});
											reactLocalStorage.setObject("toltips", {
												...toltips,
												showComponent: false,
											});
										}}>
										Ok
									</Button>
								</Box>
							}>
							<CheckBoxIcon
								fontSize='large'
								sx={{ color: "green" }}
								onClick={() => setFeatures({ name: feature, status: true })}
							/>
						</HtmlTooltip>
					</Tooltip>
				)}
			</Box>
		);
	};

	const [editSingleComponent, setEditSingleComponent] = React.useState({
		about: false,
		banner: false,
		buttons: false,
		contact: false,
		gallery: false,
		map: false,
		reviews: false,
		video: false,
	});

	const componentEditBtn = (feature) => {
		return (
			<>
				{databyEmail?.status !== "Inactive" && (
					<Box
						className='borderColor'
						sx={{
							position: "absolute",
							top: editSingleComponent[feature] === false ? 11 : -21,
							left: editSingleComponent[feature] === false ? 11 : -7,
							zIndex: 999,
							backgroundColor: "white",
							lineHeight: 0,
							border:
								editSingleComponent[feature] === false ? "3px solid" : "none",
							borderRadius: "5px",
							cursor: "pointer",
						}}>
						{editSingleComponent[feature] === false ? (
							<Tooltip title='Click to edit this section.'>
								<HtmlTooltip
									open={toltips?.editComponent}
									title={
										<Box>
											<Typography className='textColor'>
												You can use this button to edit this section.
											</Typography>
											<Button
												sx={{ border: "1px solid", py: 0, mt: 1.5 }}
												onClick={() => {
													setToltips({
														...toltips,
														editComponent: false,
														doneEditing: true,
													});
													reactLocalStorage.setObject("toltips", {
														...toltips,
														editComponent: false,
														doneEditing: true,
													});
												}}>
												Ok
											</Button>
										</Box>
									}>
									<EditIcon
										fontSize='large'
										sx={{ color: "red" }}
										onClick={() => {
											const updatedData = Object.fromEntries(
												Object.entries(editSingleComponent).map(([key]) => [
													key,
													false,
												]),
											);
											Swal.fire({
												title: "Are you sure?",
												text: `You want to Edit this?`,
												icon: "warning",
												showCancelButton: true,
												confirmButtonColor: "#3085d6",
												cancelButtonColor: "#d33",
												confirmButtonText: "Yes, Edit it!",
											}).then(async (result) => {
												if (result.isConfirmed) {
													setEditSingleComponent({
														...updatedData,
														[feature]: true,
													});
												}
											});
										}}
									/>
								</HtmlTooltip>
							</Tooltip>
						) : (
							<Tooltip title='Complete Editing?'>
								<HtmlTooltip
									open={toltips?.doneEditing}
									title={
										<Box>
											<Typography className='textColor'>
												Use this button if you are done editing this section.
											</Typography>
											<Button
												sx={{ border: "1px solid", py: 0, mt: 1.5 }}
												onClick={() => {
													setToltips({
														...toltips,
														doneEditing: false,
														hideComponent: true,
													});
													reactLocalStorage.setObject("toltips", {
														...toltips,
														doneEditing: false,
														hideComponent: true,
													});
												}}>
												Ok
											</Button>
										</Box>
									}>
									<Button
										sx={{
											border: "1px solid white !important",
											backgroundColor: "green !important",
										}}
										onClick={() =>
											Swal.fire({
												title: "Are you sure?",
												text: `Finish Editing?`,
												icon: "warning",
												showCancelButton: true,
												confirmButtonColor: "#3085d6",
												cancelButtonColor: "#d33",
												confirmButtonText: "Yes, Done!",
											}).then(async (result) => {
												if (result.isConfirmed) {
													setEditSingleComponent({
														...editSingleComponent,
														[feature]: false,
													});
												}
											})
										}>
										Done Edititng
									</Button>
								</HtmlTooltip>
								{/* <CheckBoxIcon
							fontSize='large'
							sx={{ color: "green" }}
							
						/> */}
							</Tooltip>
						)}
					</Box>
				)}
			</>
		);
	};

	return (
		<Box sx={{ width: { md: "100%", sm: "100%", xs: "100vw" } }}>
			<>
				{databyEmail?.status !== "Inactive" && (
					<>
						<HtmlTooltip
							open={toltips?.closeEditingMode}
							title={
								<Box>
									<Typography className='textColor'>
										You can use this button to close the editing mode for your
										card.
									</Typography>
									<Button
										sx={{ border: "1px solid", py: 0, mt: 1.5 }}
										onClick={() => {
											setToltips({
												...toltips,
												closeEditingMode: false,
												editComponent: true,
											});
											reactLocalStorage.setObject("toltips", {
												...toltips,
												closeEditingMode: false,
												editComponent: true,
											});
										}}>
										Ok
									</Button>
								</Box>
							}>
							<Button
								onClick={() => {
									Swal.fire({
										title: "Are you sure?",
										text: `You want to ${
											!edit ? "Edit" : "Close Editing Mode for"
										} your card`,
										icon: "warning",
										showCancelButton: true,
										confirmButtonColor: "#3085d6",
										cancelButtonColor: "#d33",
										confirmButtonText: "Yes",
									}).then((result) => {
										if (result.isConfirmed) {
											setEdit(!edit);
										}
									});
								}}
								sx={{
									backgroundColor: edit ? "red !important" : "green!important",
									color: "white",
									border: "1px solid",
									px: 2,
								}}>
								Close Editing Mode
							</Button>
						</HtmlTooltip>
					</>
				)}
				<Box sx={{ maxWidth: "500px", mx: "auto" }}>
					<Box
						sx={{
							pointerEvents:
								databyEmail?.status === "Inactive" && "none !important",
						}}>
						<Box
							sx={{
								position: "relative",
							}}>
							{componentEditBtn("banner")}
							{editSingleComponent.banner ? (
								<BannerPhotoComp
									featureActiveBtn={featureActiveBtn}
									findFeature={findFeature}
								/>
							) : (
								<Banner />
							)}
						</Box>
						<Box sx={{ position: "relative" }}>
							{componentEditBtn("buttons")}
							{editSingleComponent.buttons ? (
								<ButtonsLinkComp
									featureActiveBtn={featureActiveBtn}
									findFeature={findFeature}
								/>
							) : (
								<Buttons />
							)}
						</Box>
						<Box sx={{ position: "relative" }}>
							{componentEditBtn("about")}
							{editSingleComponent.about ? (
								<AboutComp
									featureActiveBtn={featureActiveBtn}
									findFeature={findFeature}
								/>
							) : (
								<About />
							)}
						</Box>
						<Box sx={{ position: "relative" }}>
							{componentEditBtn("video")}
							{editSingleComponent.video ? (
								<VideoComp
									featureActiveBtn={featureActiveBtn}
									findFeature={findFeature}
								/>
							) : (
								<Video />
							)}
						</Box>
						<Box sx={{ position: "relative" }}>
							{componentEditBtn("gallery")}
							{editSingleComponent.gallery ? (
								<ImagesComp
									featureActiveBtn={featureActiveBtn}
									findFeature={findFeature}
								/>
							) : (
								<Gallery />
							)}
						</Box>
						<Box sx={{ position: "relative" }}>
							{componentEditBtn("reviews")}
							{editSingleComponent.reviews ? (
								<ReviewsComp
									featureActiveBtn={featureActiveBtn}
									findFeature={findFeature}
								/>
							) : (
								<Reviews />
							)}
						</Box>
						<Box sx={{ position: "relative" }}>
							{componentEditBtn("map")}
							{editSingleComponent.map ? (
								<MapComp
									featureActiveBtn={featureActiveBtn}
									findFeature={findFeature}
								/>
							) : (
								<Map />
							)}
						</Box>
						<Box sx={{ position: "relative" }}>
							{componentEditBtn("contact")}
							{editSingleComponent.contact ? (
								<ContactsComp
									featureActiveBtn={featureActiveBtn}
									findFeature={findFeature}
								/>
							) : (
								<Contact />
							)}
						</Box>
						<CopyRight />
						<ShareButton />
					</Box>
				</Box>
			</>
		</Box>
	);
};

export default InActiveCard;

const BannerPhotoComp = ({ featureActiveBtn, findFeature }) => {
	return (
		<Box
			sx={{
				backgroundColor: "#80808057",
				border: "2px solid",
				p: 1,
				my: 5,
				borderRadius: "5px",
			}}
			className='borderColor'>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					boxShadow: 0,
					position: "relative",
					border:
						findFeature("Profile")?.status !== true &&
						"3px solid red !important",
					backgroundColor:
						findFeature("Profile")?.status !== true && "#ff000038 !important",
				}}>
				{featureActiveBtn("Profile")}
				<BannerPhoto />
				<Box
					sx={{
						height: { md: 200, xs: 150 },
						width: { md: 200, xs: 150 },
						mt: { md: -11, xs: -7 },
						position: "relative",
					}}>
					<ProfilePhoto />
				</Box>
			</Box>

			<Box
				sx={{
					my: 3,
					position: "relative",
					border:
						findFeature("Headlines")?.status !== true &&
						"3px solid red !important",
					backgroundColor:
						findFeature("Headlines")?.status !== true && "#ff000038 !important",
				}}>
				<div className='separator borderColor textColor'>Name and Title</div>
				{featureActiveBtn("Headlines")}
				<Headline type={"banner"} />
			</Box>

			<Box
				sx={{
					my: 3,
					position: "relative",
					backgroundColor:
						findFeature("Short-Details")?.status !== true &&
						"#ff000038 !important",
				}}>
				<div className='separator borderColor textColor'>
					Details With Quick Action
				</div>
				{featureActiveBtn("Short-Details")}
				<Details
					type='banner'
					featureActiveBtn={featureActiveBtn}
					findFeature={findFeature}
				/>
			</Box>
		</Box>
	);
};

const ButtonsLinkComp = ({ featureActiveBtn, findFeature }) => {
	return (
		<Box
			sx={{
				backgroundColor: "#80808057",
				border: "2px solid",
				p: 1,
				my: 5,
				borderRadius: "5px",
				position: "relative",
			}}
			className='borderColor'>
			{featureActiveBtn("Buttons")}
			<div className='separator borderColor textColor'>
				Quick Contact Buttons
			</div>
			<ButtonsLink
				featureActiveBtn={featureActiveBtn}
				findFeature={findFeature}
			/>
		</Box>
	);
};
const AboutComp = ({ featureActiveBtn, findFeature }) => {
	return (
		<Box
			sx={{
				backgroundColor: "#80808057",
				border: "2px solid",
				p: 1,
				my: 5,
				borderRadius: "5px",
				position: "relative",
			}}
			className='borderColor'>
			{featureActiveBtn("Details")}
			<div className='separator borderColor textColor'>About</div>
			<Details
				type='about'
				featureActiveBtn={featureActiveBtn}
				findFeature={findFeature}
			/>
		</Box>
	);
};
const VideoComp = ({ featureActiveBtn, findFeature }) => {
	return (
		<Box
			sx={{
				backgroundColor: "#80808057",
				border: "2px solid",
				p: 1,
				my: 5,
				borderRadius: "5px",
				position: "relative",
			}}
			className='borderColor'>
			{!window.location.pathname.includes("/dashboard/managevideo") &&
				featureActiveBtn("Videos")}
			<div className='separator borderColor textColor'>Videos</div>
			<AddVideo featureActiveBtn={featureActiveBtn} findFeature={findFeature} />
		</Box>
	);
};
const ImagesComp = ({ featureActiveBtn, findFeature }) => {
	return (
		<Box
			sx={{
				backgroundColor: "#80808057",
				border: "2px solid",
				p: 1,
				my: 5,
				borderRadius: "5px",
				position: "relative",
			}}
			className='borderColor'>
			{!window.location.pathname.includes("/dashboard/galleryphoto") &&
				featureActiveBtn("Gallery")}
			<div className='separator borderColor textColor'>Images</div>
			<Galleryphoto
				featureActiveBtn={featureActiveBtn}
				findFeature={findFeature}
			/>
		</Box>
	);
};
const ReviewsComp = ({ featureActiveBtn, findFeature }) => {
	return (
		<Box
			sx={{
				backgroundColor: "#80808057",
				border: "2px solid",
				p: 1,
				my: 5,
				borderRadius: "5px",
				position: "relative",
			}}
			className='borderColor'>
			{!window.location.pathname.includes("/dashboard/allreviews") &&
				featureActiveBtn("Reviews")}
			<div className='separator borderColor textColor'>Reviews</div>
			<AllReviews
				featureActiveBtn={featureActiveBtn}
				findFeature={findFeature}
			/>
		</Box>
	);
};
const MapComp = ({ featureActiveBtn, findFeature }) => {
	return (
		<Box
			sx={{
				backgroundColor: "#80808057",
				border: "2px solid",
				p: 1,
				my: 5,
				borderRadius: "5px",
				position: "relative",
			}}
			className='borderColor'>
			{featureActiveBtn("Map")}
			<div className='separator borderColor textColor'>Find</div>
			<ManageMap
				featureActiveBtn={featureActiveBtn}
				findFeature={findFeature}
			/>
		</Box>
	);
};
const ContactsComp = ({ featureActiveBtn, findFeature }) => {
	return (
		<Box
			sx={{
				backgroundColor: "#80808057",
				border: "2px solid",
				p: 1,
				my: 5,
				borderRadius: "5px",
				position: "relative",
			}}
			className='borderColor'>
			{!window.location.pathname.includes("/dashboard/allemails") &&
				featureActiveBtn("Mails")}
			<div className='separator borderColor textColor'>Contacts</div>
			<AllMails featureActiveBtn={featureActiveBtn} findFeature={findFeature} />
		</Box>
	);
};
