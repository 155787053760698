import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";

const StatisticsForUsers = ({ databyEmail, token }) => {
	const [Statistics, setStatistics] = useState();
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const StatisticsByUser = await axios.get(
					`${process.env.REACT_APP_API_PATH}/StatisticsByUser/${databyEmail?.url}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);
				setStatistics(StatisticsByUser?.data);
			} catch (error) {
				if (error.response.status) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: error.response.data,
					});
				} else {
					console.log(error);
				}
			}
		};

		if (token) {
			fetchData();
		}
	}, [databyEmail?.url, token, loading]);
	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setLoading(true);
				try {
					await axios
						.delete(
							`${process.env.REACT_APP_API_PATH}/deleteStatisticsSingle/${id}/${databyEmail?.url}`,
							{
								headers: {
									Authorization: `Bearer ${token}`,
								},
							},
						)
						.then(function (response) {
							Swal.fire("Deleted!", "That data has been deleted.", "success");
							setLoading(false);
						});
				} catch (error) {
					if (error.response.status) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: error.response.data,
						});
					} else {
						console.log(error);
					}
				}
			}
		});
	};
	const columns = [
		{
			field: "date",
			headerName: "Date",
			minWidth: 200,
			flex: 1,
		},
		{
			field: "qrScanEvents",
			headerName: "QR Scan",
			minWidth: 310,
			filterable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						{params.row?.city}, {params.row?.country}{" "}
						<img src={params.row?.countryFlag} alt={params.row?.country} />
					</>
				);
			},
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 310,
			filterable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<Button
							className='button border'
							onClick={() => handleDelete(params.row?.id)}
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<DeleteIcon />
						</Button>
					</>
				);
			},
		},
	];

	return (
		<>
			{Statistics && (
				<Box
					sx={{
						width: "100%",
						height: { md: "80vh", xs: "90vh" },
						"& .MuiDataGrid-toolbarContainer .MuiButtonBase-root": {
							border: "1px solid",
							fontSize: "0.75rem",
							padding: "0px 5px",
							textTransform: "capitalize",
						},
						"& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root": {
							fontSize: "1rem",
						},
					}}>
					<DataGrid
						componentsProps={{
							panel: {
								sx: {
									"& .MuiDataGrid-panelContent": {
										maxWidth: "100vw !important",
									},
								},
							},
						}}
						rows={Statistics?.qrScanEvents || []}
						getRowId={(row) => row?.id}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!Statistics || loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</>
	);
};

export default StatisticsForUsers;
