import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import QrCodeIcon from "@mui/icons-material/QrCode";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CollectionsIcon from "@mui/icons-material/Collections";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import StorageIcon from "@mui/icons-material/Storage";
import {
	BarChart,
	XAxis,
	YAxis,
	Bar,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const AllCharts = ({ databyEmail, findFeature, token }) => {
	const cardStyle = {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		textAlign: "left",
		padding: "0.5rem 1rem",
		minWidth: "max-content",
	};

	const iconStyle = {
		fontSize: "3rem",
		marginRight: "0.5rem",
		backgroundColor: "#8080802e",
		padding: "0.5rem",
		borderRadius: "7px",
	};
	const ContentStyle = {
		padding: "0 !important",
	};

	const [Statistics, setStatistics] = useState();
	const [BeforeInstallSubmits, setBeforeInstallSubmits] = useState();

	const [userMediaMemory, setUserMediaMemory] = useState();
	useEffect(() => {
		const fetchData = async () => {
			try {
				if (databyEmail?.url) {
					const mediaLimit = databyEmail?.features?.find(
						(item) => item?.name === "Storage",
					)?.storage;
					setUserMediaMemory({
						mediaLimit,
						mediaUsed: databyEmail?.memoryUsed,
					});
				}

				const StatisticsByUser = await axios.get(
					`${process.env.REACT_APP_API_PATH}/StatisticsByUser/${databyEmail?.url}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);
				setStatistics(StatisticsByUser?.data);

				const BeforeInstallForUsers = await axios.get(
					`${process.env.REACT_APP_API_PATH}/BeforeInstallForUsers/${databyEmail?.url}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);

				setBeforeInstallSubmits(
					BeforeInstallForUsers?.data?.[0]?.submits?.reverse(),
				);
			} catch (error) {
				if (error.response.status) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: error.response.data,
					});
				} else {
					console.log(error);
				}
			}
		};

		if (token) {
			fetchData();
		}
	}, [databyEmail, token]);

	const getChartData = (data) => {
		const countsByDate = {};
		data?.forEach((item) => {
			const date = item?.submitTime?.split(",")[0] || item?.date?.split(",")[0];
			countsByDate[date] = countsByDate[date] ? countsByDate[date] + 1 : 1;
		});

		const chartDataScanEvents = Object.keys(countsByDate).map((date) => ({
			date,
			ScanEventscount: countsByDate[date],
		}));

		const countsByCountry = {};
		data?.forEach((item) => {
			const country = item?.country;
			countsByCountry[country] = countsByCountry[country]
				? countsByCountry[country] + 1
				: 1;
		});

		const chartDataCountry = Object.keys(countsByCountry).map((country) => ({
			country,
			Countrycount: countsByCountry[country],
		}));

		const countsByCity = {};
		data?.forEach((item) => {
			const city = item?.city;
			countsByCity[city] = countsByCity[city] ? countsByCity[city] + 1 : 1;
		});

		const chartDataCity = Object.keys(countsByCity).map((city) => ({
			city,
			Citycount: countsByCity[city],
		}));
		const countsByRegion = {};
		data?.forEach((item) => {
			const region = item?.region;
			countsByRegion[region] = countsByRegion[region]
				? countsByRegion[region] + 1
				: 1;
		});

		const chartDataRegion = Object.keys(countsByRegion).map((region) => ({
			region,
			Regioncount: countsByRegion[region],
		}));

		return {
			chartDataScanEvents,
			chartDataCountry,
			chartDataCity,
			chartDataRegion,
		};
	};

	const [chartData, setChartData] = useState({
		forScan: "Date",
		forReview: "Date",
		forInstall: "Date",
	});
	const [buttons] = useState(["Country", "City", "Region", "Date"]);

	return (
		<>
			{findFeature("Mails") && (
				<Grid item lg={3} md={4} sm={6} xs={12}>
					<Link to={`/dashboard/allemails`} style={{ textDecoration: "none" }}>
						<Card style={cardStyle} className='textColor'>
							<MailIcon style={iconStyle} />
							<CardContent style={ContentStyle}>
								<Typography variant='h5'>
									{databyEmail?.emails?.length || 0}
								</Typography>
								<Typography variant='h6'>Total Mail</Typography>
							</CardContent>
						</Card>
					</Link>
				</Grid>
			)}
			{findFeature("Gallery") && (
				<Grid item lg={3} md={4} sm={6} xs={12}>
					<Link
						to={`/dashboard/galleryphoto`}
						style={{ textDecoration: "none" }}>
						<Card style={cardStyle} className='textColor'>
							<CollectionsIcon style={iconStyle} />
							<CardContent style={ContentStyle}>
								<Typography variant='h5'>
									{databyEmail?.gallery?.length || 0}
								</Typography>
								<Typography variant='h6'>Total Photos</Typography>
							</CardContent>
						</Card>
					</Link>
				</Grid>
			)}
			<Grid item lg={3} md={4} sm={6} xs={12}>
				<Card style={cardStyle} className='textColor'>
					<StorageIcon style={iconStyle} />
					<CardContent style={ContentStyle}>
						<Typography variant='h5'>
							{userMediaMemory?.mediaUsed || 0} MB/{" "}
							{userMediaMemory?.mediaLimit || 0} MB
						</Typography>
						<Typography variant='h6'>Memory Used</Typography>
					</CardContent>
				</Card>
			</Grid>
			{findFeature("Before-Install-Submits") && (
				<Grid item lg={3} md={4} sm={6} xs={12}>
					<Link
						to={`/dashboard/BeforeInstallSubmits`}
						style={{ textDecoration: "none" }}>
						<Card style={cardStyle} className='textColor'>
							<InstallMobileIcon style={iconStyle} />
							<CardContent style={ContentStyle}>
								<Typography variant='h5'>
									{BeforeInstallSubmits?.length || 0}
								</Typography>
								<Typography variant='h6'>Total Install</Typography>
							</CardContent>
						</Card>
					</Link>
				</Grid>
			)}
			{findFeature("Videos") && (
				<Grid
					item
					lg={3}
					md={4}
					sm={6}
					xs={12}
					style={{ textDecoration: "none" }}>
					<Link
						to={`/dashboard/managevideo`}
						style={{ textDecoration: "none" }}>
						<Card style={cardStyle} className='textColor'>
							<OndemandVideoIcon style={iconStyle} />
							<CardContent style={ContentStyle}>
								<Typography variant='h5'>
									{databyEmail?.videos?.length || 0}
								</Typography>
								<Typography variant='h6'>Total Videos</Typography>
							</CardContent>
						</Card>
					</Link>
				</Grid>
			)}
			{findFeature("Reviews") && (
				<Grid item lg={3} md={4} sm={6} xs={12}>
					<Link to={`/dashboard/allreviews`} style={{ textDecoration: "none" }}>
						<Card style={cardStyle} className='textColor'>
							<RateReviewIcon style={iconStyle} />
							<CardContent style={ContentStyle}>
								<Typography variant='h5'>
									{databyEmail?.reviews?.length || 0}
								</Typography>
								<Typography variant='h6'>Total Reviews</Typography>
							</CardContent>
						</Card>
					</Link>
				</Grid>
			)}
			{findFeature("Statistics") && (
				<Grid item lg={3} md={4} sm={6} xs={12}>
					<Link to={`/dashboard/Statistics`} style={{ textDecoration: "none" }}>
						<Card style={cardStyle} className='textColor'>
							<QrCodeIcon style={iconStyle} />
							<CardContent style={ContentStyle}>
								<Typography variant='h5'>
									{Statistics?.qrScanEvents?.length || 0}
								</Typography>
								<Typography variant='h6'>Total Scans</Typography>
							</CardContent>
						</Card>
					</Link>
				</Grid>
			)}
			{findFeature("Reviews") && findFeature("Statistics") && (
				<Grid item md={6} xs={12}>
					<Card style={{ padding: "1rem" }}>
						<Typography
							variant='h5'
							style={{ marginBottom: "2rem" }}
							className='textColor'>
							Total Reviews : {databyEmail?.reviews?.length || 0}
						</Typography>
						<Box>
							<ResponsiveContainer height={400}>
								<BarChart
									height={400}
									width={500}
									data={
										chartData.forReview === "Date"
											? getChartData(databyEmail?.reviews || [])
													?.chartDataScanEvents
											: chartData.forReview === "Country"
											? getChartData(databyEmail?.reviews || [])
													?.chartDataCountry
											: chartData.forReview === "City"
											? getChartData(databyEmail?.reviews || [])?.chartDataCity
											: getChartData(databyEmail?.reviews || [])
													?.chartDataRegion
									}
									margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
									<XAxis
										dataKey={
											chartData.forReview === "Date"
												? "date"
												: chartData.forReview === "Country"
												? "country"
												: chartData.forReview === "City"
												? "city"
												: "region"
										}
									/>
									<YAxis />
									<Tooltip />
									<Legend />
									<Bar
										dataKey={
											chartData.forReview === "Date"
												? "ScanEventscount"
												: chartData.forReview === "Country"
												? "Countrycount"
												: chartData.forReview === "City"
												? "Citycount"
												: "Regioncount"
										}
										name={
											chartData.forReview === "Date"
												? "Reviews by Date"
												: chartData.forReview === "Country"
												? "Reviews by Country"
												: chartData.forReview === "City"
												? "Reviews by City"
												: "Reviews by Region"
										}
										fill={databyEmail?.color?.color1st}
									/>
								</BarChart>
							</ResponsiveContainer>

							<ToggleButtonGroup
								style={{ marginTop: "1rem" }}
								sx={{
									"& .Mui-selected": {
										backgroundColor: "red !important",
									},
									"& .MuiToggleButton-root": {
										py: 1,
										px: 1.5,
										borderRadius: 1,
										border: "1px solid",
									},
								}}
								color='primary'
								value={chartData.forReview}
								exclusive
								onChange={(event, newAlignment) =>
									setChartData({ ...chartData, forReview: newAlignment })
								}
								aria-label='Platform'>
								{buttons?.map((button, key) => (
									<ToggleButton key={key} value={button}>
										{button}
									</ToggleButton>
								))}
							</ToggleButtonGroup>
						</Box>
					</Card>
				</Grid>
			)}{" "}
			{findFeature("Statistics") && findFeature("Statistics") && (
				<Grid item md={6} xs={12}>
					<Card style={{ padding: "1rem" }}>
						<Typography
							variant='h5'
							style={{ marginBottom: "2rem" }}
							className='textColor'>
							Total Scans : {Statistics?.qrScanEvents?.length || 0}
						</Typography>
						<Box>
							<ResponsiveContainer height={400}>
								<BarChart
									height={400}
									width={500}
									data={
										chartData.forScan === "Date"
											? getChartData(Statistics?.qrScanEvents || [])
													?.chartDataScanEvents
											: chartData.forScan === "Country"
											? getChartData(Statistics?.qrScanEvents || [])
													?.chartDataCountry
											: chartData.forScan === "City"
											? getChartData(Statistics?.qrScanEvents || [])
													?.chartDataCity
											: getChartData(Statistics?.qrScanEvents || [])
													?.chartDataRegion
									}
									margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
									<XAxis
										dataKey={
											chartData.forScan === "Date"
												? "date"
												: chartData.forScan === "Country"
												? "country"
												: chartData.forScan === "City"
												? "city"
												: "region"
										}
									/>
									<YAxis />
									<Tooltip />
									<Legend />
									<Bar
										dataKey={
											chartData.forScan === "Date"
												? "ScanEventscount"
												: chartData.forScan === "Country"
												? "Countrycount"
												: chartData.forScan === "City"
												? "Citycount"
												: "Regioncount"
										}
										name={
											chartData.forScan === "Date"
												? "QR Scans by Date"
												: chartData.forScan === "Country"
												? "QR Scans by Country"
												: chartData.forScan === "City"
												? "QR Scans by City"
												: "QR Scans by Region"
										}
										fill={databyEmail?.color?.color1st}
									/>
								</BarChart>
							</ResponsiveContainer>

							<ToggleButtonGroup
								style={{ marginTop: "1rem" }}
								sx={{
									"& .Mui-selected": {
										backgroundColor: "red !important",
									},
									"& .MuiToggleButton-root": {
										py: 1,
										px: 1.5,
										borderRadius: 1,
										border: "1px solid",
									},
								}}
								color='primary'
								value={chartData.forScan}
								exclusive
								onChange={(event, newAlignment) =>
									setChartData({ ...chartData, forScan: newAlignment })
								}
								aria-label='Platform'>
								{buttons?.map((button, key) => (
									<ToggleButton key={key} value={button}>
										{button}
									</ToggleButton>
								))}
							</ToggleButtonGroup>
						</Box>
					</Card>
				</Grid>
			)}{" "}
			{findFeature("Before-Install-Submits") && findFeature("Statistics") && (
				<Grid item md={6} xs={12}>
					<Card style={{ padding: "1rem" }}>
						<Typography
							variant='h5'
							style={{ marginBottom: "2rem" }}
							className='textColor'>
							Total Installs : {BeforeInstallSubmits?.length || 0}
						</Typography>
						<Box>
							<ResponsiveContainer height={400}>
								<BarChart
									height={400}
									width={500}
									data={
										chartData.forInstall === "Date"
											? getChartData(BeforeInstallSubmits || [])
													?.chartDataScanEvents
											: chartData.forInstall === "Country"
											? getChartData(BeforeInstallSubmits || [])
													?.chartDataCountry
											: chartData.forInstall === "City"
											? getChartData(BeforeInstallSubmits || [])?.chartDataCity
											: getChartData(BeforeInstallSubmits || [])
													?.chartDataRegion
									}
									margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
									<XAxis
										dataKey={
											chartData.forInstall === "Date"
												? "date"
												: chartData.forInstall === "Country"
												? "country"
												: chartData.forInstall === "City"
												? "city"
												: "region"
										}
									/>
									<YAxis />
									<Tooltip />
									<Legend />
									<Bar
										dataKey={
											chartData.forInstall === "Date"
												? "ScanEventscount"
												: chartData.forInstall === "Country"
												? "Countrycount"
												: chartData.forInstall === "City"
												? "Citycount"
												: "Regioncount"
										}
										name={
											chartData.forInstall === "Date"
												? "Installs by Date"
												: chartData.forInstall === "Country"
												? "Installs by Country"
												: chartData.forInstall === "City"
												? "Installs by City"
												: "Installs by Region"
										}
										fill={databyEmail?.color?.color1st}
									/>
								</BarChart>
							</ResponsiveContainer>

							<ToggleButtonGroup
								style={{ marginTop: "1rem" }}
								sx={{
									"& .Mui-selected": {
										backgroundColor: "red !important",
									},
									"& .MuiToggleButton-root": {
										py: 1,
										px: 1.5,
										borderRadius: 1,
										border: "1px solid",
									},
								}}
								color='primary'
								value={chartData.forInstall}
								exclusive
								onChange={(event, newAlignment) =>
									setChartData({ ...chartData, forInstall: newAlignment })
								}
								aria-label='Platform'>
								{buttons?.map((button, key) => (
									<ToggleButton key={key} value={button}>
										{button}
									</ToggleButton>
								))}
							</ToggleButtonGroup>
						</Box>
					</Card>
				</Grid>
			)}
		</>
	);
};

export default AllCharts;
