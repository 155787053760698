import {
	Backdrop,
	Box,
	Button,
	Container,
	Divider,
	Fab,
	Fade,
	Grid,
	Modal,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import SmsIcon from "@mui/icons-material/Sms";
import QRCode from "react-qr-code";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useAPI } from "../../../../apiContext";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import BeforeInstall from "../BeforeInstall/BeforeInstall";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const Buttons = () => {
	const [openInstall, setOpenInstall] = useState(false);
	const { selecetedData: data, findFeature } = useAPI();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const downloadTxtFile = () => {
		const element = document.createElement("a");
		const file = new Blob(
			[
				`
BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${data?.headline?.title}
N;CHARSET=UTF-8:;${data?.headline?.title};;;
EMAIL;CHARSET=UTF-8;type=WORK,INTERNET:${data?.links?.email}
PHOTO;ENCODING=b;TYPE=X-ICON:IMAGEDATA..
TEL;TYPE=WORK,VOICE:${data?.links?.call || ""}
TITLE;CHARSET=UTF-8:${data?.headline?.subtitle} 
URL;TYPE=WEBSITE:${window.location.origin.toString()}
URL;TYPE=FACEBOOK:${data?.links?.facebook || ""}
URL;TYPE=INSTAGRAM:${data?.links?.instagram || ""}
URL;TYPE=TWITTER:${data?.links?.twitter || ""}
END:VCARD
`,
			],
			{
				type: "text/x-vcard",
			},
		);
		element.href = URL.createObjectURL(file);
		element.download = `${data?.headline?.title}.vcf`;
		document.body.appendChild(element);
		element.click();
	};

	const [iphoneOpen, setIphoneOpen] = useState(false);
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;
	const [iphone, setIphone] = useState(false);
	useEffect(() => {
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			setIphone(true);
		}
	}, [userAgent]);
	const [promptInstall, setPromptInstall] = useState(null);
	useEffect(() => {
		const handler = (e) => {
			e.preventDefault();
			setPromptInstall(e);
		};
		window.addEventListener("beforeinstallprompt", handler);

		return () => window.removeEventListener("transitionend", handler);
	}, []);
	return (
		<>
			{findFeature("Buttons")?.status === true && (
				<Container sx={{ pt: 3 }}>
					<Box
						sx={{
							border: "3px solid",
							borderRadius: "15px",
							px: 1,
							pt: 1,
							pb: 1.5,
						}}
						className='borderColor'>
						<Typography
							className='textColor'
							sx={{ mb: 0.5 }}
							variant='h4'
							component='div'
							gutterBottom>
							{data?.headline?.quickContacts}
							<Divider className={`${data?.color?.hr}`} />
						</Typography>
						<Grid container spacing={2} className='icons'>
							{data?.links?.website !== "" && (
								<Grid
									item
									xs
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}>
									<Fab
										onClick={() =>
											window.open(`${data?.links?.website}`, "_blank")
										}
										color='primary'
										aria-label='add'>
										<LanguageIcon />
									</Fab>
									<Typography
										sx={{
											mt: 1,
											fontWeight: "bold",
											textTransform: "capitalize",
										}}
										variant='button'
										className='textColor'
										display='block'>
										Website
									</Typography>
								</Grid>
							)}{" "}
							{data?.links?.email !== "" && (
								<Grid
									item
									xs
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}>
									<Fab
										onClick={() =>
											window.open(`mailto:${data?.links?.email}`, "_blank")
										}
										color='primary'
										aria-label='add'>
										<EmailOutlinedIcon />
									</Fab>
									<Typography
										sx={{
											mt: 1,
											fontWeight: "bold",
											textTransform: "capitalize",
										}}
										variant='button'
										className='textColor'
										display='block'>
										Email
									</Typography>
								</Grid>
							)}
							{data?.links?.call !== "" && (
								<Grid
									item
									xs
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}>
									<Fab
										onClick={() => window.open(`tel:${data?.links?.call}`)}
										color='primary'
										aria-label='add'>
										<LocalPhoneIcon />
									</Fab>
									<Typography
										sx={{
											mt: 1,
											fontWeight: "bold",
											textTransform: "capitalize",
										}}
										variant='button'
										className='textColor'
										display='block'>
										Call
									</Typography>
								</Grid>
							)}
							{data?.links?.facebook !== "" && (
								<Grid
									item
									xs
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}>
									<Fab
										onClick={() =>
											window.open(`${data?.links?.facebook}`, "_blank")
										}
										color='primary'
										aria-label='add'>
										<FacebookOutlinedIcon />
									</Fab>{" "}
									<Typography
										sx={{
											mt: 1,
											fontWeight: "bold",
											textTransform: "capitalize",
										}}
										variant='button'
										className='textColor'
										display='block'>
										Facebook
									</Typography>
								</Grid>
							)}
							{data?.links?.whatsapp !== "" && (
								<Grid
									item
									xs
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}>
									<Fab
										onClick={() =>
											window.open(
												`https://api.whatsapp.com/send?phone=${data?.links?.whatsapp}`,
												"_blank",
											)
										}
										color='primary'
										aria-label='add'>
										<WhatsAppIcon />
									</Fab>
									<Typography
										sx={{
											mt: 1,
											fontWeight: "bold",
											textTransform: "capitalize",
										}}
										variant='button'
										className='textColor'
										display='block'>
										Whatsapp
									</Typography>
								</Grid>
							)}
							{data?.links?.instagram !== "" && (
								<Grid
									item
									xs
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}>
									<Fab
										onClick={() =>
											window.open(`${data?.links?.instagram}`, "_blank")
										}
										color='primary'
										aria-label='add'>
										<InstagramIcon />
									</Fab>
									<Typography
										sx={{
											mt: 1,
											fontWeight: "bold",
											textTransform: "capitalize",
										}}
										variant='button'
										className='textColor'
										display='block'>
										Instagram
									</Typography>
								</Grid>
							)}
							{data?.links?.sms !== "" && (
								<Grid
									item
									xs
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}>
									<Fab
										onClick={() =>
											window.open(`sms:${data?.links?.sms}`, "_blank")
										}
										color='primary'
										aria-label='add'>
										<SmsIcon />
									</Fab>
									<Typography
										sx={{
											mt: 1,
											fontWeight: "bold",
											textTransform: "capitalize",
										}}
										variant='button'
										className='textColor'
										display='block'>
										Text
									</Typography>
								</Grid>
							)}
							{data?.links?.twitter !== "" && (
								<Grid
									item
									xs
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}>
									<Fab
										onClick={() =>
											window.open(`${data?.links?.twitter}`, "_blank")
										}
										color='primary'
										aria-label='add'>
										<TwitterIcon />
									</Fab>{" "}
									<Typography
										sx={{
											mt: 1,
											fontWeight: "bold",
											textTransform: "capitalize",
										}}
										variant='button'
										className='textColor'
										display='block'>
										Twitter
									</Typography>
								</Grid>
							)}
							{data?.links?.youtube !== "" && (
								<Grid
									item
									xs
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}>
									<Fab
										onClick={() =>
											window.open(`${data?.links?.youtube}`, "_blank")
										}
										color='primary'
										aria-label='add'>
										<SubscriptionsOutlinedIcon />
									</Fab>
									<Typography
										sx={{
											mt: 1,
											fontWeight: "bold",
											textTransform: "capitalize",
										}}
										variant='button'
										className='textColor'
										display='block'>
										Youtube
									</Typography>
								</Grid>
							)}
							{data?.links?.linkedin !== "" && (
								<Grid
									item
									xs
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}>
									<Fab
										onClick={() =>
											window.open(`${data?.links?.linkedin}`, "_blank")
										}
										color='primary'
										aria-label='add'>
										<LinkedInIcon />
									</Fab>
									<Typography
										sx={{
											mt: 1,
											fontWeight: "bold",
											textTransform: "capitalize",
										}}
										variant='button'
										className='textColor'
										display='block'>
										LinkedIn
									</Typography>
								</Grid>
							)}
							{data?.links?.pinterest !== "" && (
								<Grid
									item
									xs
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}>
									<Fab
										onClick={() =>
											window.open(`${data?.links?.pinterest}`, "_blank")
										}
										color='primary'
										aria-label='add'>
										<PinterestIcon />
									</Fab>
									<Typography
										sx={{
											mt: 1,
											fontWeight: "bold",
											textTransform: "capitalize",
										}}
										variant='button'
										className='textColor'
										display='block'>
										Pinterest
									</Typography>
								</Grid>
							)}
							<Grid
								item
								xs
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}>
								<Fab onClick={handleOpen} color='primary' aria-label='add'>
									<QrCode2Icon />
								</Fab>
								<Typography
									sx={{
										mt: 1,
										fontWeight: "bold",
										textTransform: "capitalize",
									}}
									variant='button'
									className='textColor'
									display='block'>
									QR
								</Typography>
							</Grid>
							<Grid className='contactList' item md={12} xs={12}>
								<Button
									onClick={downloadTxtFile}
									sx={{
										px: 4,
										fontWeight: "bold",
										mt: 1.5,
										mx: 1,
										border: "1px solid",
										borderRadius: "7px",
										textTransform: "capitalize",
									}}>
									<PermContactCalendarIcon sx={{ mr: 1.5 }} />
									Add to Contacts
								</Button>
								<Button
									onClick={() => {
										if (
											findFeature("Before-Install-Submits")?.status === true
										) {
											setOpenInstall(true);
										} else {
											iphone ? setIphoneOpen(true) : promptInstall?.prompt();
										}
									}}
									sx={{
										px: 4,
										fontWeight: "bold",
										mt: 1.5,
										mx: 1,
										border: "1px solid",
										borderRadius: "7px",
										textTransform: "capitalize",
									}}>
									<InstallMobileIcon sx={{ mr: 1.5 }} /> Install
								</Button>
							</Grid>
						</Grid>
						<Modal
							aria-labelledby='transition-modal-title'
							aria-describedby='transition-modal-description'
							open={open}
							onClose={handleClose}
							closeAfterTransition
							BackdropComponent={Backdrop}
							BackdropProps={{
								timeout: 500,
							}}>
							<Fade in={open}>
								<Box sx={style}>
									<Typography
										sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
										className='textColor'
										id='transition-modal-title'
										variant='h5'>
										Scan Me
									</Typography>
									<QRCode
										fgColor={`${data?.color?.color1st}`}
										value={
											findFeature("Statistics")?.status === true
												? `${window.location.origin.toString()}/QrScan-${
														data?.url
												  }`
												: `${window.location.origin.toString()}/${data?.url}`
										}
									/>
								</Box>
							</Fade>
						</Modal>
					</Box>
				</Container>
			)}

			{openInstall && (
				<BeforeInstall
					data={data}
					setOpenInstall={setOpenInstall}
					openInstall={openInstall}
					iphoneOpen={iphoneOpen}
					setIphoneOpen={setIphoneOpen}
					iphone={iphone}
					promptInstall={promptInstall}
				/>
			)}
		</>
	);
};

export default Buttons;
