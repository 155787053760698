import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import GetAuth from "../GetAuth";
import { useAPI } from "../apiContext";

const RequireAuthAdmin = ({ children }) => {
	const { databyEmail } = useAPI();
	const { user, loading } = GetAuth();
	const location = useLocation();

	if (loading) {
		return (
			<div
				style={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
				}}>
				<span className='loader'></span>
				<span className='loader2'>Loading</span>
			</div>
		);
	}

	if (!user) {
		return <Navigate to={"/login"} state={{ from: location }} replace />;
	}

	if (databyEmail && databyEmail.role === "Admin") {
		return children;
	}

	return <Navigate to={"/dashboard"} state={{ from: location }} replace />;
};

export default RequireAuthAdmin;
