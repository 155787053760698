import { Button, CardMedia, Container, Divider, Rating } from "@mui/material";
import React, { useState } from "react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import AddReview from "./AddReview";
import { useAPI } from "../../../../apiContext";
import RateReviewIcon from "@mui/icons-material/RateReview";
SwiperCore.use([Autoplay, Pagination]);

const Reviews = () => {
	const {
		selecetedData: data,
		findFeature,
		loading,
		setLoading,
		userMediaMemory,
	} = useAPI();
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	return (
		<>
			{findFeature("Reviews")?.status === true && (
				<Container
					sx={{
						pt: 3,
						"& .swiper-slide": { height: "auto" },
						overflowX: "hidden !important",
					}}>
					<Box
						sx={{ border: "3px solid", borderRadius: "15px", p: 1 }}
						className='borderColor'>
						<Typography
							className='textColor'
							sx={{ mb: 0.5 }}
							variant='h4'
							component='div'
							gutterBottom>
							{data?.headline?.review}
							<Divider className={`${data?.color?.hr}`} />
						</Typography>
						{data?.reviews?.length > 0 ? (
							<Swiper
								loop={true}
								autoHeight={true}
								autoplay={{ delay: 4000 }}
								grabCursor={true}
								slidesPerView={3}
								spaceBetween={30}
								pagination={{
									clickable: true,
								}}
								breakpoints={{
									300: {
										slidesPerView: 1,
									},
									550: {
										slidesPerView: 2,
									},
									900: {
										slidesPerView: 2,
									},
								}}
								className='mySwiper'>
								{data?.reviews?.map((review, key) => (
									<SwiperSlide key={key}>
										<Box>
											<Card
												className='borderColor'
												sx={{
													mt: 5,
													mb: 1,
													pb: 2,
													bgcolor: "transparent",
													minHeight: "170px",
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													alignContent: "center",
													overflow: "visible",
													border: "2px solid ",
												}}>
												<CardMedia
													component='img'
													className='borderColor'
													style={{
														marginTop: -35,
														borderRadius: "50%",
														width: "80px",
														height: "80px",
														border: "5px solid ",
														backgroundColor: "white",
													}}
													image={review?.userPhoto || "/user.jpg"}
													alt=''
												/>
												<Rating
													sx={{ mt: 1.5 }}
													name='read-only'
													value={review?.rating}
													readOnly
												/>
												<Typography
													gutterBottom
													variant='h6'
													component='div'
													className='textColor'
													sx={{ mt: 1, fontWeight: "bold" }}>
													{review?.userName}
												</Typography>

												<Typography variant='body2' sx={{ mt: 1, px: 1 }}>
													{review?.userReview}
												</Typography>
											</Card>
										</Box>
									</SwiperSlide>
								))}
							</Swiper>
						) : (
							<Typography
								className='textColor'
								sx={{ mb: 0.5, fontWeight: 900 }}
								variant='h6'
								component='div'
								gutterBottom>
								No Reviews !...
							</Typography>
						)}
						<Button
							onClick={() => handleOpen()}
							variant='contained'
							sx={{
								mt: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "7px",
								backgroundColor: "transparent",
								border: "1px solid",
							}}>
							<RateReviewIcon sx={{ mr: 1.5 }} />
							Leave A Review
						</Button>
						{open && (
							<AddReview
								userData={data}
								loading={loading}
								setLoading={setLoading}
								userMediaMemory={userMediaMemory}
								findFeature={findFeature}
								open={open}
								setOpen={setOpen}
							/>
						)}
					</Box>
				</Container>
			)}
		</>
	);
};
export default Reviews;
