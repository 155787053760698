import axios from 'axios';

// Default system prompt that establishes the AI assistant's identity
const DEFAULT_SYSTEM_PROMPT = 
  "You are DigiCardsPro Assistant, an AI expert in digital business cards and professional networking. " +
  "Provide concise, accurate, and helpful information about digital business cards, networking strategies, " +
  "and how to leverage DigiCardsPro features effectively. Be professional, friendly, and focus on " +
  "delivering actionable advice that helps users maximize their professional networking opportunities.";

/**
 * Send a message to OpenAI and get a response
 * @param {string} message - User's message
 * @param {Array} conversationHistory - Previous conversation for context
 * @param {Object} options - Configuration options
 * @returns {Promise<string>} - AI's response text
 */
export const sendMessageToOpenAI = async (
  message, 
  conversationHistory = [], 
  options = {}
) => {
    try {
        // Prepare conversation context
        const messages = [
            {
                role: "system",
                content: options.systemPrompt || DEFAULT_SYSTEM_PROMPT
            },
            // Add conversation history for context
            ...conversationHistory.flatMap(exchange => [
                { role: "user", content: exchange.user },
                { role: "assistant", content: exchange.assistant }
            ]).slice(-10), // Keep last 5 exchanges (10 messages) for context window management
            {
                role: "user",
                content: message
            }
        ];

        const response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
                model: options.model || "gpt-3.5-turbo",
                messages: messages,
                temperature: options.temperature || 0.7,
                max_tokens: options.maxTokens || 400,
                top_p: options.topP || 1,
                frequency_penalty: options.frequencyPenalty || 0,
                presence_penalty: options.presencePenalty || 0.2
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
                },
                timeout: 30000 // 30 second timeout
            }
        );
        
        return response.data.choices[0].message.content;
    } catch (error) {
        // Enhanced error handling
        if (error.response) {
            // The request was made and the server responded with a non-2xx status
            console.error('OpenAI API error:', error.response.data);
            throw new Error(error.response.data.error?.message || 'Error from OpenAI API');
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received:', error.request);
            throw new Error('No response received from AI service. Please check your internet connection.');
        } else {
            // Something happened in setting up the request
            console.error('Error setting up request:', error.message);
            throw new Error('Failed to connect to AI service');
        }
    }
};

/**
 * Get suggestions for user inquiries
 * @returns {Array<string>} Array of suggested questions
 */
export const getSuggestedQuestions = () => [
    "How can I customize my digital business card?",
    "What analytics can I track with DigiCardsPro?",
    "How do I share my digital business card?",
    "What are the benefits of digital vs paper cards?",
    "Can I integrate my social media profiles?"
];
