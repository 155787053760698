import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Card,
	Divider,
	Grid,
	Tooltip,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";

import axios from "axios";
import { useAPI } from "../apiContext";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import HomepageMain from "../Pages/Homepage/HomepageMain";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

const SelectPackage = ({ setPackageSelected }) => {
	const { selectedFeatures, selecetedData } = useAPI();
	const [open, setOpen] = React.useState(false);
	const [allFeatures, setAllFeatures] = useState();
	const handleClose = () => setOpen(false);
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_PATH}/ManagePackage`)
			.then((res) => {
				setAllFeatures(res.data?.packages);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const [check, setCheck] = useState({
		status: false,
		name: "",
	});

	const [priceType, setPriceType] = useState("Monthly");

	return (
		<Box sx={{ mx: "auto" }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<Button
					className='bgColor'
					onClick={() => setPriceType("Monthly")}
					sx={{
						backgroundColor: priceType === "Monthly" && "gray !important",
						pointerEvents: priceType === "Monthly" && "none !important",
						borderRadius: "7px 0 0 7px !important",
						border: "1px solid ",
					}}>
					Monthly
				</Button>
				<Button
					className='bgColor'
					onClick={() => setPriceType("Yearly")}
					sx={{
						backgroundColor: priceType === "Yearly" && "gray !important",
						pointerEvents: priceType === "Yearly" && "none !important",
						borderRadius: "0 7px 7px 0 !important",
						border: "1px solid ",
					}}>
					Yearly
				</Button>
			</Box>
			<Table
				aria-label='Pricing table'
				sx={{
					width: "auto",
					tableLayout: "auto",
					"& .MuiTableCell-root": {
						border: "1px solid rgba(224, 224, 224, 1)",
					},
					mx: "auto",
					display: {
						xs: "none",
						sm: "table",
					},
				}}>
				<TableHead>
					<TableRow
						sx={{
							backgroundColor: "rgba(0, 0, 0, 0.04)",
						}}>
						<TableCell></TableCell>
						{allFeatures?.map((pkg, index) => (
							<TableCell key={index} align='center' width={200}>
								<Typography variant='h5'>{pkg.name}</Typography>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>
							{priceType === "Monthly" ? "Monthly Price" : "Yearly Price"}
							<br />
							{priceType === "Monthly"
								? "Billed Monthly in USD"
								: "Billed Yearly in USD"}{" "}
						</TableCell>
						{allFeatures?.map((pkg, index) => (
							<TableCell
								key={index}
								align='center'
								sx={{
									minHeight: "200px",
								}}>
								{priceType === "Monthly" ? (
									<Box
										sx={{
											py: 1,
										}}>
										<Typography variant='h6'>
											<span>{pkg.priceMonthly}$</span>
											<span> / Month</span>
										</Typography>
										<Typography variant='subtitle'>
											<span>{(pkg.priceMonthly * 12).toFixed(1)}$</span>
											<span> total a year</span>
										</Typography>
									</Box>
								) : (
									<Box
										sx={{
											py: 1,
										}}>
										<Typography variant='h6'>
											<span>{(pkg.priceYearly / 12).toFixed(1)}$</span>
											<span> / Month</span>
										</Typography>
										<Typography variant='subtitle'>
											<span>{pkg.priceYearly}$</span>
											<span> total a year</span>
										</Typography>
									</Box>
								)}
							</TableCell>
						))}
					</TableRow>
					{allFeatures?.[0]?.features.map((feature, index) => (
						<TableRow key={index}>
							<TableCell>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										fontSize: "17px",
									}}>
									{feature.name === "Statistics" ? "Analytics" : feature.name}
									<Tooltip title={feature?.details} arrow>
										<InfoIcon
											className='textColor'
											sx={{ fontSize: "15px", cursor: "pointer", ml: 1.5 }}
										/>
									</Tooltip>
								</Box>
							</TableCell>

							{allFeatures?.map((pkg, pkgIndex) => {
								const pkgFeature = pkg.features.find(
									(feat) => feat.id === feature.id,
								);
								return (
									<TableCell key={pkgIndex} align='center'>
										{pkgFeature?.name === "Storage" ? (
											<>
												<Typography className='textColor'>
													{pkgFeature.storage} MB
												</Typography>
											</>
										) : (
											<>
												{pkgFeature && pkgFeature.status ? (
													<DoneIcon sx={{ color: "green" }} />
												) : (
													<CloseIcon sx={{ color: "red" }} />
												)}
											</>
										)}
									</TableCell>
								);
							})}
						</TableRow>
					))}
					<TableRow>
						<TableCell>
							<Typography variant='h6'>
								Free Trial for 7 days {">>>"}
							</Typography>
						</TableCell>
						{allFeatures?.map((pack) => (
							<TableCell>
								<Box
									sx={{
										"& .packBtn:hover": {
											backgroundColor: "white !important",
											color: "black !important",
											border: "1px solid",
										},
										"& .packBtnConfirm:hover": {
											backgroundColor: "white !important",
											color: "green !important",
											border: "1px solid green",
										},
									}}>
									<Box
										onClick={() => {
											selectedFeatures(pack.features.filter((f) => f.status));
											setOpen(true);
										}}
										className='bgColor packBtn'
										sx={{
											color: "white !important",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											cursor: "pointer",
											border: "1px solid transparent",
										}}>
										<RemoveRedEyeIcon />{" "}
										<Typography variant='subtitle1' sx={{ py: 1, ml: 1 }}>
											Sample Preview
										</Typography>
									</Box>

									<Divider sx={{ borderColor: "white" }} />
									{check?.status !== true ? (
										<Box
											onClick={() => {
												setCheck({
													status: true,
													name: pack.name,
												});
											}}
											className='bgColor packBtn'
											sx={{
												color: "white !important",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												cursor: "pointer",
												border: "1px solid transparent",
											}}>
											<DoneIcon />{" "}
											<Typography variant='subtitle1' sx={{ py: 1, ml: 1 }}>
												Get Started
											</Typography>
										</Box>
									) : (
										<>
											{check?.name === pack?.name ? (
												<Box
													onClick={() => {
														setPackageSelected({
															features: (pack.features = pack.features.filter(
																(f) => f.status,
															)),
															packName: pack.name,
														});
													}}
													className='packBtnConfirm'
													sx={{
														color: "white !important",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														cursor: "pointer",
														border: "1px solid transparent",
														backgroundColor: "green !important",
													}}>
													<DoneIcon />{" "}
													<Typography variant='subtitle1' sx={{ py: 1, ml: 1 }}>
														Confirm
													</Typography>
												</Box>
											) : (
												<Box
													onClick={() => {
														setCheck({
															status: true,
															name: pack.name,
														});
													}}
													className='bgColor packBtn'
													sx={{
														color: "white !important",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														cursor: "pointer",
														border: "1px solid transparent",
													}}>
													<DoneIcon />{" "}
													<Typography variant='subtitle1' sx={{ py: 1, ml: 1 }}>
														Get Started
													</Typography>
												</Box>
											)}
										</>
									)}
								</Box>
							</TableCell>
						))}
					</TableRow>
				</TableBody>
			</Table>
			<Box>
				<Grid
					container
					spacing={3}
					sx={{
						justifyContent: "center",

						display: {
							xs: "block",
							sm: "none",
						},
					}}>
					{allFeatures?.map((pack, key) => (
						<Grid item key={key}>
							<Card
								variant='outlined'
								sx={{ width: "330px", borderRadius: "25px" }}>
								<Box
									className='bgColor'
									sx={{
										color: "white !important",
									}}>
									<Typography variant='h5' sx={{ py: 1 }}>
										{pack?.name}
									</Typography>
									<Box sx={{ display: "flex" }}>
										{priceType === "Monthly" ? (
											<Box
												sx={{
													color: "white !important",
													border: "1px solid white",
													width: "100%",
													py: 1,
												}}>
												<Typography variant='h6'>
													<span>{pack.priceMonthly}$</span>
													<span> / Month</span>
												</Typography>
												<Typography variant='subtitle'>
													<span>{(pack.priceMonthly * 12).toFixed(1)}$</span>
													<span> total a year</span>
												</Typography>
											</Box>
										) : (
											<Box
												sx={{
													color: "white !important",
													border: "1px solid white",
													width: "100%",
													py: 1,
												}}>
												<Typography variant='h6'>
													<span>{(pack.priceYearly / 12).toFixed(1)}$</span>
													<span> / Month</span>
												</Typography>
												<Typography variant='subtitle'>
													<span>{pack.priceYearly}$</span>
													<span> total a year</span>
												</Typography>
											</Box>
										)}
									</Box>
									<Typography variant='h6' sx={{ py: 1 }}>
										Free Trial for 7 days
									</Typography>
								</Box>

								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										my: 1.5,
									}}>
									{pack?.features?.map((feature, k) => (
										<Box
											key={k}
											sx={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												my: 0.5,
											}}>
											{feature.status ? (
												<DoneIcon sx={{ color: "green" }} />
											) : (
												<CloseIcon sx={{ color: "red" }} />
											)}
											<Typography
												className='textColor'
												sx={{
													mx: 1,
													fontSize: "18px",
												}}>
												{feature?.id}
											</Typography>
											{feature?.name === "Storage" && (
												<>
													<Typography
														sx={{ fontSize: "18px", fontWeight: "bold" }}
														className='textColor'>
														{feature.storage} MB
													</Typography>
												</>
											)}
											<Tooltip title={feature?.details} arrow>
												<InfoIcon
													className='textColor'
													sx={{ fontSize: "18px", cursor: "pointer" }}
												/>
											</Tooltip>
										</Box>
									))}
								</Box>

								<Box
									sx={{
										"& .packBtn:hover": {
											backgroundColor: "white !important",
											color: "black !important",
											border: "1px solid",
										},
										"& .packBtnConfirm:hover": {
											backgroundColor: "white !important",
											color: "green !important",
											border: "1px solid green",
										},
									}}>
									<Box
										onClick={() => {
											selectedFeatures(pack.features.filter((f) => f.status));
											setOpen(true);
										}}
										className='bgColor packBtn'
										sx={{
											color: "white !important",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											cursor: "pointer",
											border: "1px solid transparent",
										}}>
										<RemoveRedEyeIcon />{" "}
										<Typography variant='h6' sx={{ py: 1, ml: 1 }}>
											Sample Preview
										</Typography>
									</Box>

									<Divider sx={{ borderColor: "white" }} />
									{check?.status !== true ? (
										<Box
											onClick={() => {
												setCheck({
													status: true,
													name: pack.name,
												});
											}}
											className='bgColor packBtn'
											sx={{
												color: "white !important",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												cursor: "pointer",
												borderRadius: "0 0 25px 25px",
												border: "1px solid transparent",
											}}>
											<DoneIcon />{" "}
											<Typography variant='h6' sx={{ py: 1, ml: 1 }}>
												Get Started
											</Typography>
										</Box>
									) : (
										<>
											{check?.name === pack?.name ? (
												<Box
													onClick={() => {
														setPackageSelected({
															features: (pack.features = pack.features.filter(
																(f) => f.status,
															)),
															packName: pack.name,
														});
													}}
													className='packBtnConfirm'
													sx={{
														color: "white !important",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														cursor: "pointer",
														borderRadius: "0 0 25px 25px",
														border: "1px solid transparent",
														backgroundColor: "green !important",
													}}>
													<DoneIcon />{" "}
													<Typography variant='h6' sx={{ py: 1, ml: 1 }}>
														Confirm
													</Typography>
												</Box>
											) : (
												<Box
													onClick={() => {
														setCheck({
															status: true,
															name: pack.name,
														});
													}}
													className='bgColor packBtn'
													sx={{
														color: "white !important",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														cursor: "pointer",
														borderRadius: "0 0 25px 25px",
														border: "1px solid transparent",
													}}>
													<DoneIcon />{" "}
													<Typography variant='h6' sx={{ py: 1, ml: 1 }}>
														Get Started
													</Typography>
												</Box>
											)}
										</>
									)}
								</Box>
							</Card>
						</Grid>
					))}
				</Grid>
			</Box>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}>
				<Fade in={open}>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: "100%",
							maxWidth: 500,
							height: "99vh",
							bgcolor: "background.paper",
							boxShadow: 24,
						}}>
						<Box
							sx={{
								height: "calc(100% - 70px)",
								overflowY: "scroll",
								p: 1,
							}}>
							<HomepageMain data={selecetedData} />
						</Box>
						<Box
							className='borderColor'
							sx={{
								height: "50px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								borderTop: "1px solid",
							}}>
							<Button
								sx={{
									px: 5,
									border: "1px solid",
									borderRadius: "7px",
								}}
								onClick={() => setOpen(false)}>
								Close
							</Button>
						</Box>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
};

export default SelectPackage;
