import React from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useAPI } from "../../../../apiContext";

const ShortInfo = () => {
	const { selecetedData: data, findFeature } = useAPI();
	return (
		<>
			{findFeature("Short-Details")?.status === true && (
				<>
					<Typography
						className='textColor'
						gutterBottom
						sx={{
							whiteSpace: "pre-line",
							fontSize: "1rem",
							mt: 1.5,
						}}>
						{data?.details?.shortDetails}
					</Typography>

					<Button
						onClick={() =>
							window.open(
								`${
									data?.details?.quickActionBtnType === "Phone"
										? `tel:${data?.details?.quickActionLink}`
										: data?.details?.quickActionBtnType === "Email"
										? `mailto:${data?.details?.quickActionLink}`
										: data?.details?.quickActionLink
								}`,
								"_blank",
							)
						}
						sx={{
							px: 4,
							fontWeight: "bold",
							mt: 1.5,
							border: "1px solid",
							borderRadius: "7px",
						}}>
						{data?.details?.quickActionBtn}
					</Button>
				</>
			)}
		</>
	);
};

export default ShortInfo;
