import {
	Backdrop,
	Button,
	Chip,
	CircularProgress,
	NativeSelect,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box } from "@mui/system";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import GetAuth from "../../../GetAuth";

const AllUsers = () => {
	const [loading, setLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const { token } = GetAuth();
	const [users, setUsers] = useState();
	const [allPayments, setAllPayments] = useState();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const allusers = await axios.get(
					`${process.env.REACT_APP_API_PATH}/allusers`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);
				const payments = await axios.get(
					`${process.env.REACT_APP_API_PATH}/payments`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);
				setUsers(allusers.data);
				setAllPayments(payments.data);
			} catch (error) {
				if (error.response.status) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: error.response.data,
					});
				} else {
					console.log(error);
				}
			}
		};

		if (token) {
			fetchData();
		}
	}, [loading, submitting, token]);
	const handleDelete = async (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setLoading(true);
				try {
					await axios
						.delete(`${process.env.REACT_APP_API_PATH}/deleteUser/${id}`, {
							headers: {
								Authorization: `Bearer ${token}`,
							},
						})
						.then(function (response) {
							Swal.fire("Deleted!", "That user has been deleted.", "success");
							setLoading(false);
						});
				} catch (error) {
					if (error.response.status) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: error.response.data,
						});
					} else {
						console.log(error);
					}
				}
			}
		});
	};
	const handleStatus = async (id, status) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: `Yes, ${status}  it!`,
		}).then(async (result) => {
			if (result.isConfirmed) {
				setLoading(true);
				try {
					await axios
						.put(
							`${process.env.REACT_APP_API_PATH}/updateStatus/${id}`,
							{
								status: status,
							},
							{
								headers: {
									Authorization: `Bearer ${token}`,
								},
							},
						)
						.then(function (response) {
							Swal.fire({
								icon: "success",
								title: "Successfully Updated",
								showConfirmButton: false,
								timer: 1500,
							});
							setLoading(false);
						});
				} catch (error) {
					if (error.response.status) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: error.response.data,
						});
					} else {
						console.log(error);
					}
				}
			}
		});
	};

	const findPayment = (user) => {
		const payment = allPayments?.find((payment) => payment?.user === user);
		return payment;
	};

	const columns = [
		{
			field: "title",
			headerName: "User Name",
			minWidth: 200,
			filterable: false,
			flex: 1,
			renderCell: (params) => {
				return <>{params.row?.headline?.title}</>;
			},
		},
		{
			field: "email",
			headerName: "Email",
			minWidth: 240,
			flex: 1,
		},
		{
			field: "Role",
			headerName: "Role",
			minWidth: 170,
			filterable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						<NativeSelect
							onChange={(e) => {
								Swal.fire({
									title: "Are you sure?",
									icon: "warning",
									showCancelButton: true,
									confirmButtonColor: "#3085d6",
									cancelButtonColor: "#d33",
									confirmButtonText: `Yes, Change Role!`,
								}).then(async (result) => {
									if (result.isConfirmed) {
										setSubmitting(true);
										try {
											await axios
												.put(
													`${process.env.REACT_APP_API_PATH}/rolechange/${params.row?._id}`,
													{
														role: e.target.value,
													},
													{
														headers: {
															Authorization: `Bearer ${token}`,
														},
													},
												)
												.then(function (response) {
													setSubmitting(false);
													Swal.fire({
														icon: "success",
														title: "Successfully Updated",
														showConfirmButton: false,
														timer: 1500,
													});
												});
										} catch (error) {
											if (error.response.status) {
												Swal.fire({
													icon: "error",
													title: "Oops...",
													text: error.response.data,
												});
											} else {
												console.log(error);
											}
										}
									} else {
										setSubmitting(false);
									}
								});
							}}
							sx={{ minWidth: "95px" }}
							defaultValue={params.row?.role}>
							<option value={"Admin"}>Admin</option>
							<option value={"User"}>User</option>
						</NativeSelect>
					</>
				);
			},
		},
		{
			field: "Package",
			headerName: "Package",
			minWidth: 150,
			filterable: false,
			flex: 1,
			renderCell: (params) => {
				return <>{findPayment(params.row?.url)?.package}</>;
			},
		},

		{
			field: "subscriptionStartedTime",
			headerName: "Subscription Started On",
			minWidth: 200,
			filterable: false,
			flex: 1,
			renderCell: (params) => {
				return <>{findPayment(params.row?.url)?.subscriptionStartedTime}</>;
			},
		},
		{
			field: "subscriptionStatus",
			headerName: "Subscription Status",
			minWidth: 150,
			filterable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						{findPayment(params.row?.url)?.subscriptionStatus === "active" ? (
							<Chip
								label={findPayment(params.row?.url)?.subscriptionStatus}
								color='success'
							/>
						) : findPayment(params.row?.url)?.subscriptionStatus ===
						  "trialing" ? (
							<Chip
								label={findPayment(params.row?.url)?.subscriptionStatus}
								color='primary'
							/>
						) : (
							<Chip
								label={findPayment(params.row?.url)?.subscriptionStatus}
								color='error'
							/>
						)}
					</>
				);
			},
		},
		{
			field: "currentPeriodStart",
			headerName: "Current Period Start",
			minWidth: 200,
			filterable: false,
			flex: 1,
			renderCell: (params) => {
				return <>{findPayment(params.row?.url)?.currentPeriodStart}</>;
			},
		},
		{
			field: "currentPeriodEnd",
			headerName: "Current Period End",
			minWidth: 200,
			filterable: false,
			flex: 1,
			renderCell: (params) => {
				return <>{findPayment(params.row?.url)?.currentPeriodEnd}</>;
			},
		},
		{
			field: "status",
			headerName: "Card Status",
			minWidth: 150,
			flex: 1,
			renderCell: (params) => {
				return (
					<>
						{params.row.status === "Active" ? (
							<Chip label={params.row.status} color='success' />
						) : (
							<Chip label={params.row.status} color='error' />
						)}
					</>
				);
			},
		},
		{
			field: "joiningTime",
			headerName: "Joining Time",
			minWidth: 200,
			flex: 1,
		},
		{
			field: "referredBy",
			headerName: "Referred By",
			minWidth: 200,
			flex: 1,
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 310,
			filterable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<Box sx={{ display: "flex" }} className='online'>
						{params.row?.status === "Active" && (
							<Button
								onClick={() =>
									window.open(`/@${params.row?.url}`, "_blank").focus()
								}
								className='button border'
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "transparent",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<LanguageIcon />
							</Button>
						)}
						{params.row?.role === "Admin" ? (
							<Button
								disabled
								className=' border'
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "gray !important",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<DeleteIcon />
							</Button>
						) : (
							<Button
								className='button border'
								onClick={() => handleDelete(params.row?._id)}
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "transparent",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<DeleteIcon />
							</Button>
						)}
						{params.row?.role === "Admin" ? (
							<Button
								disabled
								className=' border'
								sx={{
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "gray !important",
									borderRadius: "25px",
									m: 0.5,
								}}
								variant='contained'>
								<CloseIcon />
							</Button>
						) : (
							<>
								{params.row?.status === "Inactive" ? (
									<Button
										className='button border'
										onClick={() => handleStatus(params.row?._id, "Active")}
										sx={{
											fontWeight: "bold",
											border: "2px solid",
											backgroundColor: "green !important",
											borderRadius: "25px",
											m: 0.5,
										}}
										variant='contained'>
										<DoneIcon />
									</Button>
								) : (
									<Button
										className='button border'
										onClick={() => handleStatus(params.row?._id, "Inactive")}
										sx={{
											fontWeight: "bold",
											border: "2px solid",
											backgroundColor: "#ef1a1a !important",
											borderRadius: "25px",
											m: 0.5,
										}}
										variant='contained'>
										<CloseIcon />
									</Button>
								)}
							</>
						)}
					</Box>
				);
			},
		},
	];

	return (
		<Box sx={{ p: 2 }}>
			{users && allPayments && (
				<Box
					sx={{
						width: "100%",
						height: { md: "80vh", xs: "90vh" },
						"& .MuiDataGrid-toolbarContainer .MuiButtonBase-root": {
							border: "1px solid",
							fontSize: "0.75rem",
							padding: "0px 5px",
							textTransform: "capitalize",
						},
						"& .MuiDataGrid-toolbarContainer .MuiSvgIcon-root": {
							fontSize: "1rem",
						},
					}}>
					<DataGrid
						componentsProps={{
							panel: {
								sx: {
									"& .MuiDataGrid-panelContent": {
										maxWidth: "100vw !important",
									},
								},
							},
						}}
						rows={users || []}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!users}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default AllUsers;
