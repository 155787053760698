import { Box, Button, Container, Divider, Typography } from "@mui/material";
import React from "react";
import SwiperCore, { Autoplay, Pagination, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Gallery.css";
import { useAPI } from "../../../../apiContext";
SwiperCore.use([EffectCoverflow, Autoplay, Pagination]);

const Gallery = () => {
	const { selecetedData: data, findFeature } = useAPI();
	return (
		<>
			{findFeature("Gallery")?.status === true && (
				<Container
					sx={{
						pt: 5,
						"& .swiper-slide": { height: "auto" },
						overflowX: "hidden !important",
					}}>
					<Box
						sx={{ border: "3px solid", borderRadius: "15px", p: 1 }}
						className='borderColor'>
						<Typography
							className='textColor'
							sx={{ mb: 0.5 }}
							variant='h4'
							component='div'
							gutterBottom>
							{data?.headline?.gallery}
							<Divider className={`${data?.color?.hr}`} />
						</Typography>
						{data?.gallery?.length > 0 ? (
							<Swiper
								effect={"coverflow"}
								autoHeight={true}
								grabCursor={true}
								loop={true}
								autoplay={{ delay: 2500 }}
								centeredSlides={true}
								slidesPerView={"auto"}
								initialSlide={1}
								coverflowEffect={{
									rotate: 50,
									stretch: 0,
									depth: 100,
									modifier: 1,
									slideShadows: false,
								}}
								pagination={true}>
								{data?.gallery?.map((a, key) => (
									<SwiperSlide key={key}>
										<img src={a?.imageLink2} alt='' />

										{(a?.buttonLink !== "" || a?.buttonText !== "") && (
											<>
												<Typography
													className='textColor'
													variant='body2'
													component='div'>
													{a?.title}
												</Typography>
												<Button
													onClick={() =>
														window.open(`${a?.buttonLink}`, "_blank")
													}
													sx={{
														px: 4,
														fontWeight: "bold",
														mt: 1,
														py: 0.4,
														border: "1px solid",
														borderRadius: "7px",
														textTransform: "capitalize",
													}}>
													{a?.buttonText}
												</Button>
											</>
										)}
									</SwiperSlide>
								))}
							</Swiper>
						) : (
							<Typography
								className='textColor'
								sx={{ mb: 0.5, fontWeight: 900 }}
								variant='h6'
								component='div'
								gutterBottom>
								No Photos !...
							</Typography>
						)}
					</Box>
				</Container>
			)}
		</>
	);
};

export default Gallery;
