import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import React from "react";
import { useAPI } from "../../../apiContext";
import ForAdmin from "./ForAdmin";
import ForUser from "./ForUser";

const BeforeInstallSubmits = () => {
	const { databyEmail } = useAPI();
	const [dataToRender, setDataToRender] = React.useState("My Data");

	return (
		<Box sx={{ p: 2 }}>
			{databyEmail?.role === "Admin" ? (
				<>
					<Box sx={{ mb: 2 }}>
						<Button
							sx={{
								backgroundColor:
									dataToRender === "My Data" ? "#3f51b5 !important" : "",
								border: "1px solid #3f51b5",
								borderRadius: "25px 0 0 25px",
								px: 2,
							}}
							onClick={() => {
								setDataToRender("My Data");
							}}>
							My Data
						</Button>
						<Button
							sx={{
								backgroundColor:
									dataToRender === "All Data" ? "#3f51b5 !important" : "",
								border: "1px solid #3f51b5",
								borderRadius: "0 25px 25px 0",
								px: 2,
							}}
							onClick={() => {
								setDataToRender("All Data");
							}}>
							All Data
						</Button>
					</Box>
					{dataToRender === "My Data" ? <ForUser /> : <ForAdmin />}
				</>
			) : (
				<ForUser />
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!databyEmail}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default BeforeInstallSubmits;
