import React, { useContext, useState, createContext, useEffect } from "react";
import axios from "axios";
import GetAuth from "./GetAuth";
import { Backdrop, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { reactLocalStorage } from "reactjs-localstorage";

const APIContext = createContext();

export function APIContextProvider({ children }) {
	const { user, token } = GetAuth();
	const [data, setData] = useState();

	const [loading, setLoading] = useState(false);
	React.useEffect(() => {
		const url = window.location.pathname.split("/")[1];
		axios
			.get(`${process.env.REACT_APP_API_PATH}/alluser/${url || "owner"}`)
			.then((res) => {
				setData(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [loading]);

	const [databyEmail, setDatabyEmail] = useState();
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const userbyemail = await axios.get(
					`${process.env.REACT_APP_API_PATH}/userbyemail/${user?.email}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);
				setDatabyEmail(userbyemail.data);
			} catch (error) {
				if (error.response.status) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: error.response.data,
					});
				} else {
					console.log(error);
				}
			}
		};

		if (token) {
			fetchData();
		}
	}, [token, user, loading]);

	const url = window.location.pathname;
	const [selecetedData, setSelecetedData] = useState();
	const [features, setFeatures] = useState([]);
	React.useEffect(() => {
		const demoData = {
			reviews: [
				{
					reviewId: "review652421",
					userName: "John Doe",
					userReview: "test review",
					rating: 5,
					userPhoto: "https://i.pravatar.cc/300",
				},
				{
					reviewId: "review652421",
					userName: "John Doe",
					userReview: "test review",
					rating: 5,
					userPhoto: "https://i.pravatar.cc/300",
				},
			],
			gallery: [
				{
					imageLink2: "https://picsum.photos/600/400",
					title: "test title",
					buttonLink: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
					buttonText: "test button text",
				},
				{
					imageLink2: "https://picsum.photos/600/400",
					title: "test title",
					buttonLink: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
					buttonText: "test button text",
				},
			],
			videos: [
				{
					videoLink: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
					title: "test title",
					buttonLink: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
					buttonText: "test button text",
				},
				{
					videoLink: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
					title: "test title",
					buttonLink: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
					buttonText: "test button text",
				},
			],
			color: {
				color1st: "#3d4bb8",
				hr: "hr",
			},
			details: {
				details:
					"Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, possimus temporibus nemo quia, laudantium iusto nobis suscipit culpa vero exercitationem nihil sed, reiciendis aut fuga dolorum. Facere voluptatibus officiis perspiciatis, dolorem sunt officia tempora quam autem praesentium dicta, non unde nesciunt quas iure odio qui quisquam distinctio ratione, omnis beatae nam delectus sit. Obcaecati delectus nisi doloribus enim expedita aliquam in officia similique, totam laudantium accusamus deleniti molestias quasi vitae voluptatibus veritatis minus error quisquam perferendis itaque vero voluptatum! Saepe minus odio officia dicta, deleniti deserunt delectus nihil maiores nemo repellat est illo, aliquam dolores quae accusantium, possimus enim tenetur.",
				shortDetails:
					"Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae laudantium rerum, maxime ullam expedita esse!",
				quickActionLink: "123456789",
				quickActionBtn: "Call Now",
				quickActionBtnType: "Phone",
			},
			links: {
				website: "website link here",
				skype: "skype link here",
				email: "name@email.com",
				facebook: "facebook link here",
				whatsapp: "123456789",
				youtube: "youtube link here",
				sms: "123456789",
				call: "123456789",
				twitter: "twitter link here",
				instagram: "instagram link here",
				pinterest: "pinterest link here",
				linkedin: "linkedin link here",
			},
			headline: {
				title: "John Doe",
				subtitle: "Test Title",
				quickContacts: "Quick Contacts",
				contact: "Contact Me",
				review: "Reviews",
				video: "Videos",
				gallery: "Gallery",
				aboutUs: "Bio",

				findUs: "Find Us",
			},
			profile: {
				imageLink2: "",
			},
			features: features,
			banner: {
				imageLink2: "",
			},
			map: {
				type: "map",
				map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5629.732922299864!2d-77.03954035180496!3d38.897451252130345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b7bcdecbb1df%3A0x715969d86d0b76bf!2sThe%20White%20House!5e1!3m2!1sen!2sbd!4v1682868818378!5m2!1sen!2sbd" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
			},
			status: "Active",
		};

		if (url.includes("/dashboard")) {
			setSelecetedData(databyEmail);
		} else if (url.includes("/createAccount")) {
			setSelecetedData(demoData);
		} else {
			setSelecetedData(data);
		}
	}, [data, databyEmail, features, url]);

	const findFeature = (feature) => {
		return (
			selecetedData &&
			selecetedData?.features?.find(
				(item) => item?.name === feature && item.status === true,
			)
		);
	};
	const [userMediaMemory, setUserMediaMemory] = useState();
	useEffect(() => {
		if (selecetedData?.url) {
			const mediaLimit = selecetedData?.features?.find(
				(item) => item?.name === "Storage",
			)?.storage;
			setUserMediaMemory({
				mediaLimit,
				mediaUsed: selecetedData?.memoryUsed,
			});
		}
	}, [selecetedData]);
	const [toltips, setToltips] = React.useState();

	useEffect(() => {
		setToltips(
			databyEmail?.status === "Inactive"
				? {}
				: Object.keys(reactLocalStorage.getObject("toltips"))?.length > 0
				? reactLocalStorage.getObject("toltips")
				: {
						dashboardBig: true,
						dashboardSmall: true,
						editPrevBtn: false,
						contactSupport: false,
						profileLink: false,
						closeEditingMode: false,
						editComponent: false,
						doneEditing: false,
						hideComponent: false,
						showComponent: false,
				  },
		);
	}, [databyEmail]);
	return (
		<APIContext.Provider
			value={{
				data: data,
				databyEmail: databyEmail,
				selecetedData,
				setLoading,
				loading,
				findFeature,
				userMediaMemory,
				token,
				selectedFeatures: setFeatures,
				toltips,
				setToltips,
			}}>
			{children}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</APIContext.Provider>
	);
}

export function useAPI() {
	const context = useContext(APIContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}
