import React, { useEffect, useState } from "react";
import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { useAPI } from "../../../apiContext";

const ManagePackage = () => {
	const [submitting, setSubmitting] = useState(false);
	const [allFeatures, setAllFeatures] = useState();
	const { token } = useAPI();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const ManagePackage = await axios.get(
					`${process.env.REACT_APP_API_PATH}/ManagePackage`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);
				setAllFeatures(ManagePackage?.data?.packages);
			} catch (error) {
				if (error.response.status) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: error.response.data,
					});
				} else {
					console.log(error);
				}
			}
		};

		if (token) {
			fetchData();
		}
	}, [submitting, token]);

	const handleFeatureSelect = (packageName, featureName) => {
		setAllFeatures((prevFeatures) => {
			const newFeatures = prevFeatures.map((pack) => {
				if (pack.name === packageName) {
					const newFeatures = pack.features.map((feature) => {
						if (feature.id === featureName) {
							return {
								...feature,
								status: !feature.status,
							};
						}
						return feature;
					});
					return {
						...pack,
						features: newFeatures,
					};
				}
				return pack;
			});
			return newFeatures;
		});
	};

	const stroageChange = (packageName, featureName, storage) => {
		setAllFeatures((prevFeatures) => {
			const newFeatures = prevFeatures.map((pack) => {
				if (pack.name === packageName) {
					const newFeatures = pack.features.map((feature) => {
						if (feature.id === featureName) {
							return {
								...feature,
								storage: storage || 0,
								price:
									parseFloat(storage) * parseFloat(feature.pricePerMb) || 0,
							};
						}
						return feature;
					});
					return {
						...pack,
						features: newFeatures,
					};
				}
				return pack;
			});
			return newFeatures;
		});
	};

	const changePackPriceMonthly = (packageName, price) => {
		setAllFeatures((prevFeatures) => {
			const newFeatures = prevFeatures.map((pack) => {
				if (pack.name === packageName) {
					return {
						...pack,
						priceMonthly: price || 0,
					};
				}
				return pack;
			});
			return newFeatures;
		});
	};
	const changePackPriceYearly = (packageName, price) => {
		setAllFeatures((prevFeatures) => {
			const newFeatures = prevFeatures.map((pack) => {
				if (pack.name === packageName) {
					return {
						...pack,
						priceYearly: price || 0,
					};
				}
				return pack;
			});
			return newFeatures;
		});
	};

	const detailsChange = (packageName, featureName, details) => {
		setAllFeatures((prevFeatures) => {
			const newFeatures = prevFeatures.map((pack) => {
				if (pack.name === packageName) {
					const newFeatures = pack.features.map((feature) => {
						if (feature.id === featureName) {
							return {
								...feature,
								details: details || "",
							};
						}
						return feature;
					});
					return {
						...pack,
						features: newFeatures,
					};
				}
				return pack;
			});
			return newFeatures;
		});
	};

	return (
		<Box sx={{ mx: "auto" }}>
			<Grid container spacing={3} sx={{ justifyContent: "center" }}>
				{allFeatures?.map((pack, key) => (
					<Grid item key={key}>
						<Card variant='outlined' sx={{ p: 3 }}>
							<Typography variant='h5' className='textColor' sx={{ my: 1 }}>
								{pack?.name}
							</Typography>
							<Divider className='borderColor' sx={{ my: 1 }} />
							<Box>
								<Box sx={{ my: 0.5 }}>
									<input
										style={{ width: "50px", height: "30px" }}
										type='number'
										value={pack?.priceMonthly}
										onChange={(e) =>
											changePackPriceMonthly(pack.name, e.target.value)
										}
									/>{" "}
									$/Month{" "}
								</Box>
								<Box sx={{ my: 0.5 }}>
									<input
										style={{ width: "50px", height: "30px" }}
										type='number'
										value={pack?.priceYearly}
										onChange={(e) =>
											changePackPriceYearly(pack.name, e.target.value)
										}
									/>{" "}
									$/Year
								</Box>
							</Box>
							<Divider className='borderColor' sx={{ my: 1 }} />
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-start",
								}}>
								{pack?.features?.map((feature, key) => (
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											my: 0.5,
											'& input[type="checkbox"]': {
												accentColor: feature.status && "green",
											},
										}}>
										<input
											type='checkbox'
											checked={feature.status}
											onChange={() =>
												handleFeatureSelect(pack.name, feature.id)
											}
										/>

										<Typography
											variant='h6'
											className='textColor'
											sx={{ mx: 1 }}>
											{feature.id === "Statistics"
												? "Analytics"
												: feature.id}
										</Typography>

										{feature?.name === "Storage" && (
											<>
												<Box>
													<input
														style={{ width: "50px", height: "30px" }}
														type='number'
														value={feature.storage}
														onChange={(e) =>
															stroageChange(
																pack.name,
																feature.id,
																e.target.value,
															)
														}
													/>{" "}
												</Box>
												<Typography
													variant='h6'
													className='textColor'
													sx={{ mx: 1 }}>
													MB
												</Typography>
											</>
										)}
										<input
											placeholder="Feature's Details"
											type='text'
											value={feature.details}
											onChange={(e) =>
												detailsChange(pack.name, feature.id, e.target.value)
											}
										/>
									</Box>
								))}
							</Box>
							<Divider className='borderColor' sx={{ my: 1 }} />
							<Button
								onClick={async () => {
									setSubmitting(true);
									try {
										await axios
											.put(
												`${process.env.REACT_APP_API_PATH}/ManagePackage`,
												allFeatures,
												{
													headers: {
														Authorization: `Bearer ${token}`,
													},
												},
											)
											.then(function (response) {
												setSubmitting(false);
												Swal.fire({
													icon: "success",
													title: "Saved Successfully",
													showConfirmButton: false,
													timer: 1500,
												});
											});
									} catch (error) {
										if (error.response.status) {
											Swal.fire({
												icon: "error",
												title: "Oops...",
												text: error.response.data,
											});
										} else {
											console.log(error);
										}
									}
								}}
								sx={{
									width: "85%",
									border: "1px solid",
									borderRadius: "7px",
									mt: 2,
								}}>
								Save
							</Button>
						</Card>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default ManagePackage;
