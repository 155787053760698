import axios from "axios";
import React, { useState } from "react";

const UseData = () => {
	const [data, setData] = useState();
	React.useEffect(() => {
		const url = window.location.pathname.split("/")[1];
		axios
			.get(`${process.env.REACT_APP_API_PATH}/alluser/${url || "owner"}`)
			.then((res) => {
				setData(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	return { data };
};

export default UseData;
