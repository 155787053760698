import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	Button,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
} from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import HowToInstall from "../Buttons/HowToInstall";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	width: {
		xs: "90%",
		sm: "auto",
	},
};

const BeforeInstall = ({
	data,
	setOpenInstall,
	openInstall,
	iphoneOpen,
	setIphoneOpen,
	iphone,
	promptInstall,
}) => {
	const [location, setLocation] = useState(null);
	useEffect(() => {
		axios
			.get(
				`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_ipgeolocation}`,
			)
			.then((res) => {
				setLocation(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit } = useForm();
	const onSubmit = ({ userName, email }) => {
		const datas = {
			infoId: `InfoId${Math.floor(100000 + Math.random() * 900000)}`,
			userName,
			email,
			submitTime: new Date().toLocaleString(),
			user: data?.email,
			countryFlag: location?.country_flag,
			country: location?.country_name,
			city: location?.city,
			region: location?.state_prov,
			zip: location?.zipcode,
			latitude: location?.latitude,
			longitude: location?.longitude,
			ip: location?.ip,
			organization: location?.organization,
			isp: location?.isp,
		};
		setSubmitting(true);
		axios
			.put(
				`${process.env.REACT_APP_API_PATH}/BeforeInstall/${data?.url}`,
				datas,
			)
			.then((res) => {
				setSubmitting(false);
				setOpenInstall(false);
				iphone ? setIphoneOpen(true) : promptInstall?.prompt();
			})
			.then((error) => console.log(error));
	};

	return (
		<div>
			{iphoneOpen && (
				<HowToInstall iphoneOpen={iphoneOpen} setIphoneOpen={setIphoneOpen} />
			)}
			{data?._id ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={openInstall}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={openInstall}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<CancelIcon
								onClick={() => setOpenInstall(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>
							<Typography
								className='textColor'
								sx={{
									fontWeight: 900,
									textAlign: "center",
								}}
								variant='subtitle1'
								component='div'
								gutterBottom
								data-aos='fade-right'>
								Provide Some Info Before Install
							</Typography>

							<Box
								sx={{
									mx: "auto",
									maxWidth: "350px",
								}}>
								<form data-aos='fade-left' onSubmit={handleSubmit(onSubmit)}>
									<TextField
										size='small'
										required
										sx={{ width: "100%", mb: 1 }}
										id='"outlined-multiline-flexible'
										label='Name'
										{...register("userName", { required: true })}
									/>
									<TextField
										size='small'
										required
										sx={{ width: "100%", mb: 1 }}
										id='"outlined-multiline-flexible'
										label='Email'
										{...register("email", { required: true })}
									/>
									<Button
										type='submit'
										variant='contained'
										className='button border'
										sx={{
											width: "100%",
											mb: 1,
											px: 3,
											fontWeight: "bold",
											border: "2px solid",
											backgroundColor: "transparent",
											borderRadius: "7px",
										}}>
										Submit
									</Button>
								</form>
							</Box>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 999,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default BeforeInstall;
