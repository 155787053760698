import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Rating,
	TextField,
	Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import StarIcon from "@mui/icons-material/Star";

const AddReview = ({
	userData,
	loading,
	setLoading,
	userMediaMemory,
	findFeature,
	setOpen,
	open,
}) => {
	const { register, handleSubmit, reset } = useForm();

	const [image, setImage] = useState(null);

	const handleClose = () => {
		setOpen(false);
	};

	const handleImageChange = (event) => {
		const file = event.target.files[0];
		if (file && file.size > 1.5 * 1024 * 1024) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "File size is too big! Max file size is 1.5MB",
			});
		} else {
			setImage(file);
		}
	};
	const [ratings, setRatings] = React.useState();
	const [location, setLocation] = useState();
	useEffect(() => {
		if (findFeature("Statistics")) {
			axios
				.get(
					`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_ipgeolocation}`,
				)
				.then((res) => {
					setLocation(res.data);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [findFeature]);
	const onSubmit = async ({ userName, userReview, email }) => {
		let file;
		if (image) {
			const data = new FormData();
			data.append("file", image);
			data.append("upload_preset", "DigiCardsPro-Review");
			setLoading(true);
			const res = await fetch(
				"https://api.cloudinary.com/v1_1/freedomvirtualsolutions/image/upload",
				{
					method: "POST",
					body: data,
				},
			);
			file = await res.json();
		}

		const uploadData = {
			type: "Ratings",
			userPhoto: file?.secure_url,
			pre_public_id: file?.public_id,
			bytes: file?.bytes,
			reviewId: `review${Math.floor(100000 + Math.random() * 900000)}`,
			userName,
			userReview,
			email,
			rating: ratings,
			receiver: userData?.email,
			countryFlag: location?.country_flag,
			country: location?.country_name,
			city: location?.city,
			region: location?.state_prov,
			zip: location?.zipcode,
			latitude: location?.latitude,
			longitude: location?.longitude,
			ip: location?.ip,
			organization: location?.organization,
			isp: location?.isp,
			submitTime: new Date().toLocaleString(),
		};

		await axios
			.put(
				`${process.env.REACT_APP_API_PATH}/review/${userData?._id}`,
				uploadData,
			)
			.then(function (response) {
				Swal.fire({
					icon: "success",
					title: "Success!",
					showConfirmButton: false,
					timer: 1500,
				});
				setLoading(false);
				setOpen(false);
				reset();
				setImage(null);
				setRatings(null);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const handleCancel = () => {
		setImage(null);
		setOpen(false);
	};

	return (
		<>
			<>
				<Dialog
					open={open}
					onClose={handleClose}
					sx={{
						"& .MuiDialog-paper": {
							width: { md: "50vw", xs: "95vw" },
							p: 2,
						},
					}}>
					{!loading ? (
						<form data-aos='fade-left' onSubmit={handleSubmit(onSubmit)}>
							<Typography
								className='textColor'
								sx={{
									fontWeight: 900,
									textAlign: "center",
								}}
								variant='h4'
								component='div'
								gutterBottom
								data-aos='fade-right'>
								Leave A Review
								<Typography
									variant='caption'
									display='block'
									sx={{ color: "red" }}>
									Your posts will appear publicly with your profile name and
									picture. <br /> Your posts will appear across the web.
								</Typography>
							</Typography>
							<DialogContent sx={{ overflow: "hidden" }}>
								{parseFloat(userMediaMemory?.mediaUsed) <
								parseFloat(userMediaMemory?.mediaLimit) ? (
									<>
										{image ? (
											<>
												<img
													src={URL.createObjectURL(image)}
													alt='selected'
													style={{ width: "100%", maxHeight: "450px" }}
												/>
												<IconButton
													onClick={() => setImage(null)}
													sx={{ mr: 1 }}>
													<CancelIcon />
												</IconButton>
											</>
										) : (
											<Typography>
												Your Photo (Optional) <br />
												No image selected
											</Typography>
										)}
										<input
											type='file'
											accept='image/*'
											onChange={handleImageChange}
											sx={{ mt: 2 }}
										/>
									</>
								) : (
									<Typography sx={{ p: 2 }}>
										<span style={{ color: "red" }}>
											Photo Upload is not available for this user at this time.
											You can still leave a review without a photo.
											<br />
										</span>
									</Typography>
								)}
								<br />

								<>
									<Box
										sx={{
											mt: 3,
											display: "flex",
											justifyContent: "center",
										}}>
										<Rating
											className='color-theme'
											sx={{
												float: "left",
												mb: 2,
												fontSize: 55,
											}}
											name='hover-feedback'
											value={ratings}
											size='large'
											precision={0.5}
											onChange={(event, newValue) => {
												setRatings(newValue);
											}}
											emptyIcon={
												<StarIcon
													className='color-text'
													style={{ opacity: 0.55 }}
													fontSize='inherit'
												/>
											}
										/>
									</Box>
									{ratings && (
										<>
											<input
												type='text'
												value={ratings}
												hidden
												{...register("ratings", { required: true })}
											/>
											<TextField
												size='small'
												required
												sx={{ width: "100%", mb: 2 }}
												id='"outlined-multiline-flexible'
												label='Name'
												{...register("userName", { required: true })}
											/>
											<TextField
												size='small'
												required
												sx={{ width: "100%", mb: 2 }}
												id='"outlined-multiline-flexible'
												label='Email'
												{...register("email", { required: true })}
											/>
											<TextField
												size='small'
												required
												sx={{ width: "100%", mb: 2 }}
												id='"outlined-multiline-flexible'
												label='Share Your Review'
												multiline
												rows={4}
												{...register("userReview", { required: true })}
											/>
										</>
									)}
								</>
							</DialogContent>
							<DialogActions
								sx={{ display: "flex", justifyContent: "space-between" }}>
								<Button onClick={handleCancel}>Cancel</Button>
								{ratings && (
									<div>
										<IconButton onClick={() => handleClose()} sx={{ mr: 1 }}>
											<CancelIcon />
										</IconButton>
										<IconButton type='submit'>
											<CheckCircleIcon />
										</IconButton>
									</div>
								)}
							</DialogActions>
						</form>
					) : (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								minHeight: "300px",
							}}>
							<CircularProgress sx={{ mt: 2 }} />
							<Typography className='textColor' sx={{ mt: 2 }}>
								Sending...
							</Typography>
						</Box>
					)}
				</Dialog>
			</>
		</>
	);
};

export default AddReview;
